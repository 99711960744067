import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";

//api load ds thuốc
export async function apiLayDsThuoc(dataRequest) {
  try {
    let res = await common_post(apis.lay_ds_thuoc_trong_kho, dataRequest);
    
    if (res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis apiLayDsThuoc lỗi: ", error);
    return;
  }
}

//api lấy thông tin chi tiết thuốc
export async function layChiTietThuoc(dataRequest) {
  try {
    const res = await common_post(apis.lay_chi_tiet_thuoc, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layChiTietThuoc lỗi: ", error);
    return;
  }
}

//lấy ds thẻ kho
export async function layDSTheKho(dataRequest) {
  try {
    const res = await common_post(apis.lay_ds_the_kho, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layChiTietThuoc lỗi: ", error);
    return;
  }
}

//lấy ds lô - hạn sử dụng của lô theo thuốc
export async function layDSLoTheoThuoc(dataRequest) {
  try {
    const res = await common_post(apis.lay_ds_lo_theo_thuoc, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layChiTietThuoc lỗi: ", error);
    return;
  }
}

//lấy ds lô - hạn sử dụng của lô theo thuốc
export async function apiSuaTrangThaiThuoc(dataRequest) {
  try {
    const res = await common_post(apis.sua_trang_thai_thuoc, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layChiTietThuoc lỗi: ", error);
    return;
  }
}

//lấy ds nhóm thuốc
export const apiLayDSNhomThuoc = async ({
  BENH_VIEN_ID,
  search_string,
  partner_code,
}) => {
  let dataRequest = { BENH_VIEN_ID, search_string, partner_code };
  try {
    const res = await common_post(apis.lay_ds_nhom_thuoc_hieu_luc, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layDSNhomThuoc lỗi: ", error);
    return;
  }
};

//thêm mới nhóm thuốc
export const apiThemMoiNhomThuoc = async ( data = {} ) => {
  const req = { ...data };

  try {
    const res = await common_post(apis.them_moi_nhom_thuoc, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    HLog("Apis themMoiNhomThuoc lỗi: ", error);
    return null;
  }
};

//chỉnh sửa nhóm thuốc
export const apiSuaNhomThuoc = async (data = {}) => {
  const req = { ...data };

  try {
    const res = await common_post(apis.sua_thong_tin_nhom_thuoc, req);
    if (res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    HLog("Apis suaNhomThuoc lỗi: ", error);
    return null;
  }
};

// export thuốc
export const apiExportThuoc = async ({
  BENH_VIEN_ID = "",
  partner_code = "",
  template = 0,
}) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_thuoc_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template thuoc error" : "Export ds thuoc error");
  }
};

// import thuốc
export const apiNhapDsThuoc = async ({
  BENH_VIEN_ID = "",
  data = [],
  partner_code = "",
}) => {
  try {
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_them_thuoc_v2, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach thuoc error", error);
  }
};

//lấy ds nhóm thuốc
export const apiLayDSKhoThuoc = async (dataRequest) => {
  try {
    const res = await common_post(apis.lay_ds_kho_thuoc_hieu_luc, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layDSNhomThuoc lỗi: ", error);
    return;
  }
};

//lấy ds nhóm thuốc
export const apiLayDataExportTheKho = async (dataRequest) => {
  try {
    const res = await common_post(apis.xuat_exel_the_kho, dataRequest);
    if (res.status === "OK") {
      return res;
    }
  } catch (error) {
    HLog("Apis layDSNhomThuoc lỗi: ", error);
    return;
  }
};