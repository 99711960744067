import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apis } from "../../../constants";
import { widthDrawerKeys } from "../../../constants/keys";
import {common_post, isEmptyObject} from "../../../helpers";
import { useEventListener } from "../../../hooks";
import i18n, { languageKeys } from "../../../i18n";
import FormDrawer from "../../FormDrawer/FormDrawer";
import ModalPDF from "../../Modal/ModalPDF";
import style from "./style.module.less";
import ChiTietLichSuKham from "../ChiTietLichSuKham";

function LichSuKhamDrawer({ patientInfo = {} , onChangeVisibleDetail = () => {}}, ref) {
  const [visible, setVisible] = useState(false);

  const userProfile = useSelector((state) => state.auth.user);
  const pdfRef = useRef();
  const [isModalActive, setIsModalActive] = useState(false);
  const [currentData, setCurrentData] = useState()
  const [dataKhamBenh, setDataKhamBenh] = useState([])
  const [dataDonThuoc, setDataDonThuoc] = useState([])
  const [dataKetquaCls, setDataKqCls] = useState([])

  useImperativeHandle(ref, () => ({
    openModal(data) {
      handleOpen(data)
    },
    closeModal() {
      handleClose();
    },
    visible,
  }));


  // console.log(tabDonThuoc, "ok");
  function handleClose() {
    setVisible(false);
    onChangeVisibleDetail(false)
  }

  function handleOpen(data) {
    getChiTietLDT(data.LUOT_DIEU_TRI_ID)
    setCurrentData(data)
    setVisible(true)
    onChangeVisibleDetail(true)
  }

  const handlePrints = (id_don_thuoc) => {
    // let id = chiTietRef.current.thongTinDonThuoc.DON_THUOC_ID;
    const dataRequest = {
      partner_code: userProfile.partner_code,
      DON_THUOC_ID: id_don_thuoc,
    };
    const api = apis.lay_data_in_don_thuoc;
    pdfRef.current.openModal(dataRequest, api);
  };

  const getChiTietLDT = async (LUOT_DIEU_TRI_ID) => {
    try{
      const response = await common_post(apis.khambenh_lay_chi_tiet_lich_su_luot_dieu_tri, {
        LUOT_DIEU_TRI_ID,
        partner_code: userProfile.partner_code
      })
      if (response.status === "OK") {
        let { LUOT_DIEU_TRI, CLS, DON_THUOC } = response
        LUOT_DIEU_TRI && !isEmptyObject(LUOT_DIEU_TRI) ? setDataKhamBenh([LUOT_DIEU_TRI]) : setDataKhamBenh([])
        CLS && !isEmptyObject(CLS) ?  setDataKqCls([CLS]) : setDataKqCls([])
        DON_THUOC && !isEmptyObject(DON_THUOC) ? setDataDonThuoc([DON_THUOC]) : setDataDonThuoc([])
      }
    }catch (e) {

    }
  }

  // hàm xử lý phím tắt
  function keydownEvent(event) {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (event.key === "Escape") {
      if (!isModalActive) {
        stopDefaultAction(event);
        handleClose();
      }
    }
  }
  useEventListener("keydown", keydownEvent, window, visible);

  return (
    <FormDrawer
      width={widthDrawerKeys.large}
      visible={visible}
      title={i18n.t(languageKeys.chi_tiet_cuoc_kham)}
      //txt_them_moi_kho_thuoc
      //onClickBack={() => handleClose()}
      onCancel={() => handleClose()}
      hiddenTopAction={true}
      keyboard={true}
      footer={
        <div className={style["exitBtn"]}>
          <Button onClick={handleClose} type="primary" ghost>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </div>
      }
    >
      {/*<ChiTietLichSuKhamBenh*/}
      {/*  selectedAppointment={dataDaChon}*/}
      {/*  newAccess={resetTab}*/}
      {/*  donThuocTab={setTabDonThuoc}*/}
      {/*  setinfoDonThuoc={setinfoDonThuoc}*/}
      {/*  patientInfo={patientInfo}*/}
      {/*  setIsModalActive={setIsModalActive}*/}
      {/*/>*/}
      <div className={style["container"]}>
        <ChiTietLichSuKham
            setIsModalActive={setIsModalActive}
            dataKhamBenh = {dataKhamBenh}
            dataDonThuoc = {dataDonThuoc}
            dataKetquaCls = {dataKetquaCls}
            patientInfo={patientInfo}
        />
      </div>
      <ModalPDF ref={pdfRef} />
    </FormDrawer>
  );
}
export default React.forwardRef(LichSuKhamDrawer);
