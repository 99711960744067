import { createSlice } from "@reduxjs/toolkit";
import { indexedDbKeys } from "../../constants/keys";

const initState = {
  [indexedDbKeys.tinh]: [],
  [indexedDbKeys.huyen]: [],
  [indexedDbKeys.xa]: [],
  [indexedDbKeys.dan_toc]: [],
  [indexedDbKeys.quoc_gia]: [],
  [indexedDbKeys.nghe_nghiep]: [],
  [indexedDbKeys.kham_benh]: [],
  [indexedDbKeys.cls]: [],
  [indexedDbKeys.thx]: [],
  [indexedDbKeys.template_pdf]: {},
  [indexedDbKeys.phong]: [],
  [indexedDbKeys.nhom_dv]: [],
  loaiDichVu: [],
  [indexedDbKeys.isResetSelectTab]: false,
};

const dataSlice = createSlice({
  name: "data",
  initialState: initState,
  reducers: {
    updateIndexedDb(state, action) {
      return { ...state, ...action.payload };
    },
    updateLoaiDichVu(state, action) {
      state.loaiDichVu = action.payload;
    },
    resetState() {
      return initState;
    },
    updateResetSelectTab(state, { payload }) {
      state.isResetSelectTab = payload
    },
    updateIndexedDbWithSaga: () => {},
  },
});

export const { updateIndexedDb, updateLoaiDichVu, resetState, updateResetSelectTab, updateIndexedDbWithSaga } = dataSlice.actions;

export default dataSlice.reducer;
