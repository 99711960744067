import React, { useMemo } from "react";
import style from "./index.module.less";
import { Divider, Layout, Menu, Tooltip } from "antd";
import { paths, urlKeys } from "../../constants/index.js";
import { CISIcon, DanhMucIcon, DuocNgoaiTruIcon, HomeIcon, LISIcon, PRMIcon, RISIcon, ReportIcon, PACSIcon, VatTuIcon, KhamDoanIcon } from "assets/svg";
import { useSelector } from "react-redux";
import { routeApp } from "../../constants/keys";
import i18n, { languageKeys, languages } from "../../i18n";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import qs from "query-string";

const RightMenu = ({ currentFeature }) => {

  const { user, tokens } = useSelector((state) => state.auth);
  const { GOP, TACH } = user?.config?.HIEN_THI_LAYOUT_MODULE || { GOP: [], TACH: [] };
  const history = useHistory();

  let module;
  if (GOP.length === 1 && TACH.length === 0) {
    module = user.config?.MODULES;
  } else {
    module = [routeApp.CIS, ...TACH].filter(mod => user.config?.MODULES?.includes(mod));
  }

  const genLanguageParams = qs.stringify({
    lang: i18n.language
  })

  // chọn điều hướng động, thay đổi theo global;
  const menu = [
    {
      type: "divider",
    },
    {
      label: "CIS",
      key: paths.cis,
      icon: <CISIcon />,
      // disabled: !module.includes(routeApp.CIS),
      module: routeApp.CIS,
      tooltip: i18n.t(languageKeys.menu_Quan_ly_kham_benh)
    },
    {
      type: "divider",
    },
    {
      label: "LIS",
      key: `${window?.[urlKeys.LIS_WEB_UI_URL]}?access_token=${tokens?.token}&refresh_token=${tokens?.refreshToken}`,
      icon: <LISIcon />,
      // disabled: !module.includes(routeApp.LIS),
      module: routeApp.LIS,
      tooltip: i18n.t(languageKeys.tool_tip_lis)
    },
    {
      type: "divider",
    },
    {
      label: "RIS",
      key: paths.ris,
      icon: <RISIcon />,
      // disabled: !module.includes(routeApp.RIS),
      module: routeApp.RIS,
      tooltip: i18n.t(languageKeys.tool_tip_ris)
    },
    {
      type: "divider",
    },
    {
      label: "PRM",
      key: `${window?.[urlKeys.PRM_WEB_UI_URL]}?jwt=${tokens?.token}`,
      icon: <PRMIcon />,
      // disabled: !module.includes(routeApp.PRM),
      module: routeApp.PRM,
      tooltip: i18n.t(languageKeys.tool_tip_prm)
    },
    {
      type: "divider",
    },
    // {
    //   label: i18n.t(languageKeys.menu_Quan_ly_duoc_ngoai_tru),
    //   key: paths.duoc,
    //   icon: <DuocNgoaiTruIcon />,
    //   style: { height: 105 },
    //   // disabled: !module.includes(routeApp.DUOC),
    //   module: routeApp.DUOC,
    //   tooltip: i18n.t(languageKeys.tool_tip_duoc)
    // },
    {
      type: "divider",
    },
    {
      label: i18n.t(languageKeys.duoc_br_vat_tu),
      key: `${window?.[urlKeys.SUPPLIES_WEB_UI_URL]}?access_token=${tokens?.token}&refresh_token=${tokens?.refreshToken}`,
      icon: <VatTuIcon />,
      style: { height: 95 },
      module: routeApp.VAT_TU,
      module2: routeApp.DUOC,
      tooltip: i18n.t(languageKeys.vat_tu_tieu_hao_tu_truc),
    },
    {
      type: "divider",
    },
    {
      label: "PACS",
      key: "https://live.draid.ai/",
      icon: <PACSIcon />,
      module: routeApp.PACS,
      tooltip: i18n.t(languageKeys.tool_tip_pacs)
    },
    {
      type: "divider",
    },
    {
      label: i18n.t(languageKeys.khamdoan),
      key: `${window?.[urlKeys.PHR_WEB_UI_URL]}?access_token=${tokens?.token}&refresh_token=${tokens?.refreshToken}`,
      icon: <KhamDoanIcon />,
      style: { height: 95 },
      module: routeApp.KHAM_DOAN,
      tooltip: i18n.t(languageKeys.sub_title_kd)
    },
    {
      type: "divider",
    },
    {
      label: i18n.t(languageKeys.menu_Bao_cao),
      key: `${window?.[urlKeys.RP_WEB_UI_URL]}?access_token=${tokens?.token}&refresh_token=${tokens?.refreshToken}`,
      icon: <ReportIcon />,
      module: routeApp.BAO_CAO,
      tooltip: i18n.t(languageKeys.tool_tip_bao_cao)
    },
    {
      type: "divider",
    },
    {
      label: i18n.t(languageKeys.data_Quan_tri),
      key: `${window?.[urlKeys.DM_WEB_UI_URL]}?access_token=${tokens?.token}&refresh_token=${tokens?.refreshToken}`,
      icon: <DanhMucIcon />,
      // disabled: !module.includes(routeApp.DANH_MUC),
      style: i18n.language === languages.tieng_viet ? {} : { height: 90 },
      module: routeApp.DANH_MUC,
      tooltip: i18n.t(languageKeys.tool_tip_quan_tri)
    },
  ];

  const checkMenu = useMemo(() => {
    document.title = menu.find((item) => item.module === currentFeature).label;

    if (user && user.config && user.config.CUSTOM_DOMAIN) {
      let { CUSTOM_DOMAIN } = user.config;
      if (CUSTOM_DOMAIN?.WEBSITE) {
        document.title = menu.find((item) => item.module === currentFeature).label;
      }
    }
    return menu.filter((item) =>
      item.type ||
      (
        item.module !== currentFeature &&
        (module.includes(item.module) || module.includes(item.module2)) &&
        (user.MODULE_QL?.includes(item.module) || user.MODULE_QL?.includes(item.module2))
      ) ||
      item.module === routeApp.PACS
    );
  }, [currentFeature, user]);

  // Remove unwanted divider
  const removeDuplicates = (arr) => {
    const result = [];
    let prev = null;
    for (let i = 0; i < arr.length; i++) {
      const current = arr[i];
      if (current?.type !== prev?.type) {
        result.push(current);
      }
      prev = current;
    }
    return result;
  };

  const handleClickModule = (path) => {
    if (path === "https://live.draid.ai/") {
      window.open(path, "_blank")
    } else if ([paths.cis, paths.duoc, paths.software_route, paths.ris].includes(path)) {
      history.push(path)
    } else {
      window.location.href = path + "&" + genLanguageParams;
    }
  }

  const menuItem = [
    {
      label: i18n.t(languageKeys.home),
      key: paths.software_route,
      icon: <HomeIcon />,
      style: i18n.language === languages.tieng_viet ? { height: 90 } : {},
    },
    ...removeDuplicates(currentFeature === routeApp.RIS ? checkMenu : checkMenu.filter(i => i.module !== routeApp.PACS))
  ]

  return (
    <Layout.Sider width={90} className={style["right_menu"]}>
      <Menu className={style["custom_menu"]} selectedKeys={[]}>
        {menuItem?.map(item =>
          item?.type === 'divider'
            ? <Divider className={style['divider']} />
            : (
              <Tooltip title={item?.tooltip} placement="left" zIndex={9999}>
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  style={item.style ? item.style : {}}
                  onClick={() => handleClickModule(item.key)}
                >
                  <div style={{ width: '100%', lineHeight: '17px' }}>
                    {item.label}
                  </div>
                </Menu.Item>
              </Tooltip>
            )
        )}
      </Menu>
    </Layout.Sider>
  );
};

export default RightMenu;
