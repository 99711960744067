import i18n, { languageKeys } from "../../../i18n";

/* ===================================================================================================
 *                           Trường thông tin danh mục BỆNH VIỆN
 * ===================================================================================================
 */
export const fieldBenhVien = {
  id: "ID",
  ma_cskcb: "MA_CSKCB",
  ten_cskcb: "TEN_CSKCB",
  tuyen: "TUYEN",
  hang: "HANG_BV",
  tinh_thanh: "tinh_thanh",
  quan_huyen: "quan_huyen",
  xa_phuong: "xa_phuong",
  dia_chi_chi_tiet: "DIA_CHI",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục KHOA
 * ===================================================================================================
 */
export const fieldKhoa = {
  id: "ID",
  ma_khoa: "MA_KHOA",
  ten_khoa: "TEN_KHOA_PHONG",
  ma_khoa_theo_byt: "MA_CHUYEN_KHOA",
  ma_byt: "MA_BYT",
  truong_khoa: "TEN_TRUONG_KHOA",
  loai_khoa: "LOAI_KHOA",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  id_khoa_cua_ds_byt: "ID",
  ten_khoa_cua_ds_byt: "CHUYEN_KHOA",
  ma_khoa: "MA_KHOA",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục PHÒNG
 * ===================================================================================================
 */
export const fieldPhong = {
  id: "ID",
  ma_phong: "MA_PHONG",
  ten_phong: "TEN_KHOA_PHONG",
  khoa: "MA_KHOA",
  loai_phong: "LOAI_PHONG",
  phong_chi_dinh: "PHONG_CHI_DINH",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  ten_khoa: "TEN_KHOA",
  ten_phong_chi_dinh: "TEN_PHONG_CHI_DINH",
  so_phong_chi_dinh: "SO_PHONG_CHI_DINH",
  ma_phong_duoc_chi_dinh: "MA_PHONG_DUOC_CHI_DINH",
  khoa_id: "KHOA_ID",
  gio_lam_viec: "GIO_LAM_VIEC",
  gio_bat_dau: "GIO_BAT_DAU",
  gio_ket_thuc: "GIO_KET_THUC",
};
/* ===================================================================================================
 *                           Trường thông tin danh mục PHÒNG NỘI TRÚ
 * ===================================================================================================
 */
export const fieldPhongNoiTru = {
  id: "ID",
  ma_phong: "MA_PHONG",
  ma_khoa: "MA_KHOA",
  ten_phong: "TEN_KHOA_PHONG",
  khoa: "MA_KHOA",
  loai_phong: "LOAI_PHONG",
  chi_dinh: "CHI_DINH",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  buong_dieu_tri: "BUONG_DIEU_TRI",
  buong_dieu_tri_id: "BUONG_DIEU_TRI_ID",
  benh_vien_id: "BENH_VIEN_ID",
  loai_tru: "LOAI_TRU",

  ten_khoa: "TEN_KHOA",
  ten_phong_chi_dinh: "TEN_PHONG_CHI_DINH",
  so_phong_chi_dinh: "SO_PHONG_CHI_DINH",
  ma_phong_duoc_chi_dinh: "MA_PHONG_DUOC_CHI_DINH",
  khoa_id: "KHOA_ID",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục DỊCH VỤ KĨ THUẬT
 * ===================================================================================================
 */
export const fieldDichVuKiThuat = {
  id: "DV_ID",
  stt: "STT_DV",
  ma_dich_vu: "MA_DICHVU",
  ten_dich_vu: "TEN_DICHVU",
  ten_dich_vu_theo_byt: "TEN_DICHVU_BHYT",
  nhom_dich_vu: "NHOM_DV_ID",
  ten_nhom_dvkt: "TEN_NHOM_DICH_VU",
  loai_dich_vu: "LOAI_DV_ID",
  ten_loai_dvkt: "TEN_LOAI_DICH_VU",
  nhom_hoa_don: "NHOM_HOADON_ID",
  ten_nhom_hoa_don: "TEN_NHOM_HOA_DON",
  gia_thu_phi: "GIA_THU_PHI",
  gia_bhyt: "GIA_BHYT",
  gia_dich_vu: "GIA_DICHVU",
  gia_nuoc_ngoai: "GIA_NUOC_NGOAI",
  ti_le_dung_tuyen: "TYLE_DUNG_TUYEN",
  ti_le_trai_tuyen: "TYLE_TRAI_TUYEN",
  so_ngay_tai_chi_dinh: "SO_NGAY_TAI_CHI_DINH",
  phong_thuc_hien: "LIST_ID_PHONG_THUC_HIEN",
  phong_lay_mau: "LIST_ID_PHONG_LAY_MAU",
  cho_phep_mien_giam: "MIEN_GIAM",
  cho_sua_gia_khi_chi_dinh: "SUA_GIA_KHI_CHI_DINH",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  chi_so_xet_nghiem: "chi_so_xet_nghiem",
  don_vi_chi_so: "don_vi_chi_so",
  giai_ket_qua_chi_so: "giai_ket_qua_chi_so",
  don_vi_tinh: "ID_DVT",
  ten_don_vi_tinh: "TEN_DVT",
  nhom_bhyt: "NHOM_BHYT_ID",
  ten_nhom_bhyt: "TEN_NHOM_BHYT",
  nhom_cls: "NHOM_CLS",
  loai_thu_thuat: "LOAI_TT_ID",
  ten_loai_thu_thuat: "LOAI_THU_THUAT",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục NHÓM DV KĨ THUẬT
 * ===================================================================================================
 */
export const fieldNhomDvKiThuat = {
  id: "ID",
  ma_nhom: "MA_NHOM",
  ten_nhom: "TEN_NHOM",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  nhom_bhyt: "NHOM_BHYT",
  ten_nhom_bhyt: "TEN_NHOM_BHYT",
  nguoi_sua: "NGUOI_SUA",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục LOẠI DV KĨ THUẬT
 * ===================================================================================================
 */
export const fieldLoaiDvKiThuat = {
  id: "ID",
  stt: "STT_LDV",
  id_loai_dv_ki_thuat: "MA_LOAI",
  nhom_dv_ki_thuat: "NHOM_DV_ID",
  ten_loai_dv_ki_thuat: "TEN_LOAI",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  ten_nhom_dvkt: "TEN_NHOM_DICH_VU",
  nhom_bhyt: "NHOM_BHYT",
  ten_nhom_bhyt: "TEN_NHOM_BHYT",
  nguoi_sua: "NGUOI_SUA",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục NHÓM HÓA ĐƠN
 * ===================================================================================================
 */
export const fieldNhomHoaDon = {
  id: "ID",
  id_nhom: "id_nhom",
  ma_nhom: "MA_NHOM",
  ten_nhom: "TEN_NHOM",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục HÃNG SẢN XUẤT
 * ===================================================================================================
 */
export const fieldHangSanXuat = {
  id: "ID",
  ma_hang_sx: "MA_HANG_SX",
  ten_hang_sx: "TEN_HANG_SX",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục ĐƯỜNG DÙNG
 * ===================================================================================================
 */
export const fieldDuongDung = {
  id: "ID",
  ma_duong_dung: "ma_duong_dung",
  ma_duong_dung_theo_byt: "MA_DUONG_DUNG_BYT",
  ten_duong_dung: "TEN_DUONG_DUNG_BYT",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục KHO, TỦ TRỰC
 * ===================================================================================================
 */
export const fieldKhoTuTruc = {
  id: "ID",
  ma_kho: "MA_KHO",
  ten_kho: "TEN_KHO",
  thu_kho: "THU_KHO",
  loai_kho: "LOAI_KHO",
  kieu_kho: "KIEU_KHO",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục TỈNH
 * ===================================================================================================
 */
export const fieldTinh = {
  id: "ID",
  id_tinh: "MA_TINH_THANH",
  ma_tinh_byt: "MA_HANH_CHINH",
  ten_tinh: "TEN_TINH_THANH",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục HUYỆN
 * ===================================================================================================
 */
export const fieldHuyen = {
  id: "ID",
  id_huyen: "MA_QUAN_HUYEN",
  ma_huyen_byt: "MA_HANH_CHINH",
  ten_huyen: "TEN_QUAN_HUYEN",
  ma_tinh: "MA_TINH_THANH",
  ten_tinh: "TEN_TINH_THANH",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục XÃ
 * ===================================================================================================
 */
export const fieldXa = {
  id: "ID",
  id_xa: "MA_PHUONG_XA",
  ma_xa_byt: "MA_HANH_CHINH",
  ten_xa: "TEN_PHUONG_XA",
  ma_tinh: "MA_TINH_THANH",
  ma_huyen: "MA_QUAN_HUYEN",
  code_thx: "TEN_VIET_TAT",
  ten_tinh: "TEN_TINH_THANH",
  ten_huyen: "TEN_QUAN_HUYEN",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục ICD-10
 * ===================================================================================================
 */
export const fieldICD10 = {
  id: "ID_ICD",
  ma_icd: "MA_ICD",
  ten_icd: "TEN_ICD",
  nhom_icd: "NHOM_ICD",
  id_nhom_icd: "ID_ICD_CHA",
  loai_icd: "LOAI_ICD",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục NHÓM ICD-10
 * ===================================================================================================
 */
export const fieldNhomICD10 = {
  id: "ID_ICD",
  id_nhom_icd: "MA_ICD",
  ten_nhom_icd: "NHOM_ICD",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục NGHỀ NGHIỆP
 * ===================================================================================================
 */
export const fieldNgheNghiep = {
  id: "ID",
  stt: "STT",
  ma_nghe_nghiep: "MA_NGHE_NGHIEP",
  ten_nghe_nghiep: "TEN_NGHE_NGHIEP",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục DÂN TỘC
 * ===================================================================================================
 */
export const fieldDantoc = {
  id: "ID",
  ma_dan_toc: "ID",
  ten_dan_toc: "DAN_TOC",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục QUỐC GIA
 * ===================================================================================================
 */
export const fieldQuocGia = {
  id: "ID",
  ma_quoc_gia: "MA_QUOC_GIA",
  ten_quoc_gia: "TEN_QUOC_GIA",
  ten_goi_khac: "TEN_GOI_KHAC",
  ten_goi_viet_tat: "TEN_VIET_TAT",
  hien_thi: "DANG_HOAT_DONG",
  trang_thai: "TRANG_THAI",
};

/**
 * @DANH_MỤC_ĐƠN_VỊ_TÍNH
 */
export const fieldDonViTinh = {
  id: "ID_DVT",
  ma_dvt: "MA_DVT",
  ten_dvt: "TEN_DVT",
  stt: "STT",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục NHÓM BHYT
 * ===================================================================================================
 */
export const fieldNhomBhyt = {
  id: "ID",
  ten_nhom: "TEN_NHOM",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  ma_nhom: "MA_NHOM",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục Thuốc
 * ===================================================================================================
 */
export const fieldThuoc = {
  id: "ID",
  ma_thuoc: "MA_THUOC",
  ma_thuoc_bhyt: "MA_THUOC_BHYT",
  stt_bhyt: "STT_BHYT",
  ten_thuoc: "TEN_THUOC",
  ten_thuoc_bhyt: "TEN_THUOC_BHYT",
  ma_dvt: "ID_DVT",
  ten_dvt: "TEN_DVT",
  ham_luong: "HAM_LUONG",
  nong_do: "NONG_DO",
  the_tich: "THE_TICH",
  ma_hoat_chat: "ID_HOAT_CHAT",
  ten_hoat_chat: "TEN_HOAT_CHAT",
  ma_nsx: "ID_NUOC_SAN_XUAT",
  ten_nsx: "TEN_NUOC_SAN_XUAT",
  ma_hsx: "ID_HANG_SAN_XUAT",
  ten_hsx: "TEN_HANG_SX",
  quy_cach_dong_goi: "QUYCACH_DONGGOI",
  atc_code: "ATC_CODE",
  ma_nhom_dv: "ID_NHOM_DV",
  ten_nhom_dv: "TEN_NHOM_DICH_VU",
  ma_loai_dv: "ID_LOAI_DV",
  ten_loai_dv: "TEN_LOAI",
  ma_nhom_chi_phi_bhyt: "ID_NHOM_CHIPHI_BHYT",
  ten_nhom_chi_phi_bhyt: "TEN_NHOM_BHYT",
  ma_nhom_hoa_don: "ID_NHOM_HOADON",
  ten_nhom_hoa_don: "TEN_NHOM_HOA_DON",
  ti_le_dung_tuyen: "TILE_DUNGTUYEN",
  ti_le_trai_tuyen: "TILE_TRAITUYEN",
  thuoc_khang_sinh: "IS_THUOC_KHANG_SINH",
  che_pham_y_hoc_co_truyen: "IS_CHEPHAM_YHCT",
  cho_phep_mien_giam: "IS_CHO_PHEP_MIEN_GIAM",
  thuoc_tan_duoc: "IS_THUOC_TAN_DUOC",
  thuoc_ke_don: "IS_THUOC_KE_DON",
  vi_thuoc_y_hoc_co_truyen: "IS_THUOC_YHCT",
  thuc_pham_chuc_nang: "IS_THUC_PHAM_CHUC_NANG",
  tam_dung_nhap_NCC: "IS_TAM_DUNG_NHAP_NCC",
  hien_thi: "KHOA",
  gia_ban: "GIA_BAN",
  gia_nhap: "GIA_NHAP",
  ma_vach: "MA_VACH",
  ma_nhom_thuoc: "NHOM_THUOC_ID",
  ten_nhom_thuoc: "TEN_NHOM", //tên nhóm thuộc nhóm thuốc
  loai_thuoc: "LOAI_THUOC",
  so_dang_ky_thuoc: "SO_DANG_KI",
  cho_phep_ban_gia_thang_du: "IS_CHO_PHEP_GIA_THANG_DU",
  DVT_QUY_DOI: "DON_VI_TINH_QUY_DOI_ID",
  SL_QUY_DOI: "SO_LUONG_QUY_DOI",
  TEN_DVT_QD: "TEN_DON_VI_TINH_QUY_DOI",
};
/* ===================================================================================================
 *                           Trường thông tin danh mục HOẠT CHẤT
 * ===================================================================================================
 */
export const fieldHoatChat = {
  id: "ID",
  ma_hoat_chat: "MA_HOAT_CHAT",
  ma_hoat_chat_bhyt: "MA_HOAT_CHAT_BYT",
  ten_hoat_chat: "TEN_HOAT_CHAT",
  duong_dung: "ID_DUONG_DUNG_BYT",
  ten_duong_dung: "TEN_DUONG_DUNG_BYT",
  IS_YC_HOI_CHAN: "IS_YC_HOI_CHAN",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

export const fieldGiuong = {
  id: "ID",
  ma_giuong: "MA_GIUONG",
  ma_giuong_bhyt: "MA_GIUONG_BYT",
  ten_giuong: "TEN_GIUONG",
  phong: "PHONG_ID",
  ten_phong: "TEN_PHONG",
  ten_khoa: "TEN_KHOA",
  dich_vu: "DV_GIUONG",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
  ma_loai_giuong: "MA_LOAI_GIUONG",
  ten_loai_giuong: "TEN_LOAI_GIUONG",
};

export const fieldCTTT = {
  ID: "ID",
  MA_CACHTHUC_THUTHUAT: "MA_CACHTHUC_THUTHUAT",
  TEN_CACHTHUC_THUTHUAT: "TEN_CACHTHUC_THUTHUAT",
  GHI_CHU: "GHI_CHU",
  KHOA: "KHOA",
  hien_thi: "KHOA",
};
export const fieldPPTT = {
  ID: "ID",
  MA_PHUONGPHAP_THUTHUAT: "MA_PHUONGPHAP_THUTHUAT",
  TEN_PHUONGPHAP_THUTHUAT: "TEN_PHUONGPHAP_THUTHUAT",
  GHI_CHU: "GHI_CHU",
  KHOA: "KHOA",
  hien_thi: "KHOA",
};

export const dataLoaiThuoc = [
  {
    key: "THUOC_THUONG",
    name: i18n.t(languageKeys.thuoc_thuong),
  },
  {
    key: "NHA_THUOC",
    name: i18n.t(languageKeys.thuoc_nha_thuoc),
  },
];
/* ===================================================================================================
 *                           Trường thông tin danh mục XUẤT KHÁC
 * ===================================================================================================
 */
export const fieldXuatKhac = {
  ma_ly_do: "MA_LY_DO",
  ly_do: "LY_DO",
  hien_thi: "KHOA",
};
/* ===================================================================================================
 *                           Trường thông tin danh mục NGUỒN
 * ===================================================================================================
 */
export const fieldNguon = {
  ma_nguon: "MA_NGUON",
  ten_nguon: "TEN_NGUON",
  hien_thi: "KHOA",
};
/* ===================================================================================================
 *                           Trường thông tin danh mục MÁY
 * ===================================================================================================
 */

export const fieldDanhMucMay = {
  id: "ID",
  ma_may: "MA_MAY",
  ten_may: "TEN_MAY",
  ma_bhyt: "MA_BHYT",
  loai_may: "LOAI_MAY",
  hien_thi: "KHOA",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục nguồn khách
 * ===================================================================================================
 */

export const fieldDanhMucNguonKhach = {
  id: "ID",
  ma_nguon_khach: "MA_NGUON_KHACH",
  ten_nguon_khach: "TEN_NGUON_KHACH",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
  nhom_doi_tuong_nguoi_dung: 'DM_NHOM_NGUON_KHACH_ID',
  ten_doi_tuong: 'TEN_DOI_TUONG',
  ve_tinh_id: 'VT_ID',
  nhan_su_id: 'NHANSU_ID'
};

/* ===================================================================================================
 *                           Trường thông tin danh mục đối tượng
 * ===================================================================================================
 */

export const fieldDanhMucDoiTuong = {
  id: "ID",
  ma_doi_tuong: "MA_DOI_TUONG",
  loai_doi_tuong: "LOAI_DOI_TUONG",
  ten_doi_tuong: "TEN_DOI_TUONG",
  thu_tu: "THU_TU",
  ghi_chu: "GHI_CHU",
  dung_cho_cis: "DUNG_CHO_CIS",
  benh_vien_id: "BENH_VIEN_ID",
  hien_thi: "KHOA",
  trang_thai: "TRANG_THAI",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục nguồn khách
 * ===================================================================================================
 */

export const fieldDanhMucCachDung = {
  id: "ID",
  stt: "THU_TU",
  ten_cach_dung: "TEN_CACH_DUNG",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
};

/* ===================================================================================================
 *                           Trường thông tin danh mục hình thức thanh toán
 * ===================================================================================================
 */

export const fieldDanhMucHinhThuc = {
  id: "ID",
  stt: "THU_TU",
  ten_hinh_thuc_thanh_toan: "TEN_HINH_THUC_THANH_TOAN",
  ghi_chu: "GHI_CHU",
  hien_thi: "KHOA",
};
