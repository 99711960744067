import { useCallback, useEffect, useState, useRef } from "react";
import style from "./themmoithuoc.module.less";
import { Form, Row, Col, Input, Switch, InputNumber } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { Select } from "../../../../components";
import cn from "classnames";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { dataLoaiThuoc, fieldThuoc, } from "../../../QuanLyHeThong/QuanLyDanhMuc/fieldsDanhMuc";
import { apis, keys } from "../../../../constants";
import { common_post, formatNumberToPrice, HLog, isEmptyObject } from "../../../../helpers";
import { apiLayDsQuocGiaCoHieuLuc } from "../../../QuanLyHeThong/QuanLyDanhMuc/apisDanhMuc/apisDanhMuc";
import { apiLayDsHangSxCoHieuLuc, apiLayDsHoatChatCoHieuLuc } from "../../../QuanLyHeThong/QuanLyDanhMuc/apisDanhMuc/apisDmThuoc";
import PriceInput from "../../../QuanLyHeThong/QuanLyDanhMuc/components/PriceInput/PriceInput";
import { apiLayDSNhomThuoc, layChiTietThuoc } from "../../KhoThuoc/apiKhoThuoc/apisKhoThuoc";
import CaiDatDanhMuc from "pages/QuanLyHeThong/QuanLyDanhMuc/CaiDatDanhMuc";
import { keysDanhMuc } from "constants/keys";
import CustomModal from "pages/QuanLyDuocNgoaiTru/KhoThuoc/components/CustomModal/CustomModal";
import SelectTree from "pages/QuanLyDuocNgoaiTru/KhoThuoc/components/SelectTree/SelectTree";

const ThemMoiThuoc = ({ form, callBackData = (data) => {}, showDelete = false, initData = {}, visible = false, disabled = false }) => {

  const addRef = useRef();
  const hoatchatRef = useRef();

  const userProfile = useSelector((state) => state.auth.user);

  const [dsDonViTinh, setDsDonViTinh] = useState([]);
  const [dsNuocSx, setDsNuocSx] = useState([]);
  const [dsHangSx, setDsHangSx] = useState([]);
  const [hoatChat, setHoatChat] = useState([]);
  // const [dsNhomDv, setDsNhomDv] = useState([]);
  // const [dsLoaiDv, setDsLoaiDv] = useState([]);
  const [dsNhomBhyt, setDsNhomBhyt] = useState([]);
  // const [dsNhomHoaDon, setDsNhomHoaDon] = useState([]);
  // const [selectedNhomDv, setSelectedNhomDv] = useState();
  const [loadingHoatChat, setLoadingHoatChat] = useState(false);
  const [loadingNuocSx, setLoadingNuocSx] = useState(false);
  // const [loadingNhomDvkt, setLoadingNhomDvkt] = useState(false);
  // const [loadingLoaiDvkt, setLoadingLoaiDvkt] = useState(false);
  const [loadingDonViTinh, setLoadingDonViTinh] = useState(false);
  const [loadingHangSx, setLoadingHangSx] = useState(false);
  // const [loadingNhomHoaDon, setLoadingNhomHoaDon] = useState(false);
  const [dsNhomThuoc, setDsNhomThuoc] = useState([]);
  const [loadingNhomThuoc, setLoadingNhomThuoc] = useState(false);

  useEffect(() => {
    if (visible) {
      HLog("ThemMoiThuoc useEffect visible: ", visible, " initData: ", initData);

      layDsNhomBHYT();
      //layDsNhomThuoc();
      // console.log("first", dsNhomBhyt);
      // form.setFields([
      //   {
      //     name: fieldThuoc.ma_nhom_chi_phi_bhyt,
      //     value: "Thuốc",
      //   },
      // ]);
      console.log("Danh sách nhóm BHYT", dsNhomBhyt);
      if (!isEmptyObject(initData)) {
        let request = {
          partner_code: userProfile.partner_code,
          ID: initData.ID,
          BENH_VIEN_ID: initData.BENH_VIEN_ID,
        };
        layTTChiTietThuoc(request);
      } else {
        form.resetFields();
        // taoMaThuoc();
      }
    } else {
      setDsNhomThuoc([]);
      setDsDonViTinh([]);
      setDsNuocSx([]);
      setDsHangSx([]);
      setHoatChat([]);
      // setDsNhomDv([]);
      // setDsLoaiDv([]);
      setDsNhomBhyt([]);
      // setDsNhomHoaDon([]);
      // setSelectedNhomDv();
      setLoadingHoatChat(false);
      setLoadingNuocSx(false);
      // setLoadingNhomDvkt(false);
      // setLoadingLoaiDvkt(false);
      setLoadingDonViTinh(false);
      setLoadingHangSx(false);
      // setLoadingNhomHoaDon(false);
      form.resetFields();
      HLog("ThongTinThuoc resetFields...");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  //api lấy thông tin chi tiết thuốc
  const layTTChiTietThuoc = async (dataRequest) => {
    HLog("ThongTinThuoc layTTChiTietThuoc...");
    form.resetFields();
    const response = await layChiTietThuoc(dataRequest);
    if (response) {
      let dataResult = response.result;
      callBackData(dataResult);
      form.setFields(
        Object.values(fieldThuoc).map((key) => {
          //kiểm tra key tên hoạt chất
          // console.log("đây", key);
          if (key === fieldThuoc.ma_hoat_chat) {
            const value = dataResult[fieldThuoc.ten_hoat_chat];
            return {
              name: key,
              value,
            };
          }
          //kiểm tra key đơn vị tính
          if (key === fieldThuoc.ma_dvt) {
            const value = dataResult[fieldThuoc.ten_dvt];
            return {
              name: key,
              value,
            };
          }
          //kiểm tra key nước sản xuất
          if (key === fieldThuoc.ma_nsx) {
            const value = dataResult[fieldThuoc.ten_nsx];
            return {
              name: key,
              value,
            };
          }
          //kiểm tra key hãng sản xuất
          if (key === fieldThuoc.ma_hsx) {
            const value = dataResult[fieldThuoc.ten_hsx];
            return {
              name: key,
              value,
            };
          }
          //kiểm tra key nhóm chi phí
          if (key === fieldThuoc.ma_nhom_chi_phi_bhyt) {
            const value = dataResult[fieldThuoc.ten_nhom_chi_phi_bhyt];

            return {
              name: key,
              value,
            };
          }
          //quan trong

          //giá nhập
          if (key === fieldThuoc.gia_nhap) {
            const value = formatNumberToPrice(dataResult[fieldThuoc.gia_nhap]);
            return {
              name: key,
              value,
            };
          }

          //giá bán
          if (key === fieldThuoc.gia_ban) {
            const value = formatNumberToPrice(dataResult[fieldThuoc.gia_ban]);
            return {
              name: key,
              value,
            };
          }

          //nhóm thuốc
          if (key === fieldThuoc.ma_nhom_thuoc) {
            const value = dataResult[fieldThuoc.ten_nhom_thuoc];
            return {
              name: key,
              value,
            };
          }

          return {
            name: key,
            value: dataResult[key],
          };
        })
      );
    }
  };

  // const taoMaThuoc = async () => {
  //   try {
  //     let response = await genMaThuoc(userProfile.partner_code, userProfile.BENH_VIEN_ID);
  //     if (response) {
  //       let ma_thuoc = response.MA_THUOC ? response.MA_THUOC : "";
  //       let ma_thuoc_bhyt = response.MA_THUOC_BHYT ? response.MA_THUOC_BHYT : "";
  //       form.setFields([
  //         {
  //           name: fieldThuoc.ma_thuoc,
  //           value: ma_thuoc,
  //         },
  //         {
  //           name: fieldThuoc.ma_thuoc_bhyt,
  //           value: ma_thuoc_bhyt,
  //         },
  //       ]);
  //     }
  //   } catch (error) {}
  // };

  // lấy đơn vị tính
  const layDsDonViTinh = async (search_string = "") => {
    setLoadingDonViTinh(true);
    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
        limit: keys.limit,
      };

      const res = await common_post(apis.dm_don_vi_tinh_lay_ds_co_hieu_luc, requestData);

      if (res.status === "OK") setDsDonViTinh(res.result);
    } catch (error) {
      HLog("Api lay ds don vi tinh co hieu luc error", error);
    }
    setLoadingDonViTinh(false);
  };

  const layDsHoatChat = async (search_string = "") => {
    setLoadingHoatChat(true);
    let response = await apiLayDsHoatChatCoHieuLuc({
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      search_string,
      partner_code: userProfile.partner_code,
      page: 1,
      limit: keys.limit,
    });
    if (response) {
      setHoatChat(
        response.result.map((item) => ({
          [fieldHoatChat.id]: item[fieldHoatChat.id],
          [fieldHoatChat.ten_hoat_chat]: item[fieldHoatChat.ten_hoat_chat],
        }))
      );
    }
    setLoadingHoatChat(false);
  };

  const layDsQuocGia = async (search_string = "") => {
    setLoadingNuocSx(true);
    let response = await apiLayDsQuocGiaCoHieuLuc({
      search_string,
      limit: keys.limit,
    });
    if (response) {
      let dsQuocGia = [];
      response.result.forEach(i => {
        if (!!i[fieldQuocGia.id] && !!i[fieldQuocGia.ten_quoc_gia]) {
          dsQuocGia.push({
            [fieldQuocGia.id]: i[fieldQuocGia.id],
            [fieldQuocGia.ten_quoc_gia]: i[fieldQuocGia.ten_quoc_gia],
          })
        }
      });

      setDsNuocSx(dsQuocGia);
    }
    setLoadingNuocSx(false);
  };

  const layDsHangSx = async (search_string = "") => {
    setLoadingHangSx(true);
    let response = await apiLayDsHangSxCoHieuLuc({
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      search_string,
      page: 1,
      partner_code: userProfile.partner_code,
      limit: keys.limit,
    });
    if (response) {
      setDsHangSx(response.result);
    }
    setLoadingHangSx(false);
  };

  // const layDsNhomDv = async (search_string) => {
  //   setLoadingNhomDvkt(true);
  //   let response = await apiLayDsNhomDvktCoHieuLuc({
  //     BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //     search_string,
  //     page: 1,
  //     partner_code: userProfile.partner_code,
  //     limit: keys.limit,
  //   });
  //   if (response && response.status === "OK") {
  //     setDsNhomDv(
  //       response.result.map((item) => ({
  //         [fieldNhomDvKiThuat.id]: item[fieldNhomDvKiThuat.id],
  //         [fieldNhomDvKiThuat.ten_nhom]: item[fieldNhomDvKiThuat.ten_nhom],
  //       }))
  //     );
  //   }
  //   setLoadingNhomDvkt(false);
  // };

  // const layDsLoaiDv = async (search_string = "", NHOM_DV_ID) => {
  //   if (!!NHOM_DV_ID) {
  //     setLoadingLoaiDvkt(true);
  //     try {
  //       // const NHOM_DV_ID = JSON.parse(form.getFieldValue(fieldThuoc.ma_nhom_dv))[
  //       //   fieldNhomDvKiThuat.id
  //       // ];

  //       const requestData = {
  //         BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //         search_string,
  //         NHOM_DV_ID,
  //         limit: keys.limit,
  //         partner_code: userProfile.partner_code,
  //       };

  //       const res = await common_post(apis.dm_loai_dvkt_lay_ds_co_hieu_luc, requestData);

  //       if (res.status === "OK") {
  //         setDsLoaiDv(
  //           res.result.map((item) => ({
  //             [fieldLoaiDvKiThuat.id]: item[fieldLoaiDvKiThuat.id],
  //             [fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat]: item[fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat],
  //           }))
  //         );
  //       }
  //     } catch (error) {
  //       HLog("Api lay ds loai dvkt error", error);
  //     }
  //     setLoadingLoaiDvkt(false);
  //   } else {
  //     HLog("Chua chon nhom dvkt");
  //   }
  // };

  //Hàm xử lý lấy api Danh sách nhóm thuốc
  const layDsNhomThuoc = async (search_string = "") => {
    setLoadingNhomThuoc(true);
    HLog("KhoThuoc layDSKhoThuoc...");
    let response = await apiLayDSNhomThuoc({
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      search_string,
      partner_code: userProfile.partner_code,
    });
    if (response) {
      setDsNhomThuoc(response.result);
    }
    setLoadingNhomThuoc(false);
  };

  const layDsNhomBHYT = async (search_string = "") => {
    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
        PHAN_LOAI: "THUOC",
      };

      const res = await common_post(apis.dm_nhom_bhyt_lay_ds, requestData);

      if (res.status === "OK") setDsNhomBhyt(res.result);
    } catch (error) {
      HLog("Api lay ds nhom bhyt error", error);
    }
  };

  // const layNhomHoaDon = async (search_string = "") => {
  //   setLoadingNhomHoaDon(true);
  //   let response = await apiLayDsNhomHoaDonCoHieuLuc({
  //     BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //     search_string,
  //     partner_code: userProfile.partner_code,
  //     limit: keys.limit,
  //   });
  //   if (response && response.status === "OK") {
  //     setDsNhomHoaDon(response.result);
  //   }
  //   setLoadingNhomHoaDon(false);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchHoatChat = useCallback(
    debounce((value) => layDsHoatChat(value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchNuocSx = useCallback(
    debounce((value) => layDsQuocGia(value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDonViTinh = useCallback(
    debounce((value) => layDsDonViTinh(value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchHangSx = useCallback(
    debounce((value) => layDsHangSx(value), 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleSearchNhomHoaDon = useCallback(
  //   debounce((value) => layNhomHoaDon(value), 1000),
  //   []
  // );

  const fieldHoatChat = {
    id: "ID",
    ma_hoat_chat: "MA_HOAT_CHAT",
    ma_hoat_chat_bhyt: "MA_HOAT_CHAT_BYT",
    ten_hoat_chat: "TEN_HOAT_CHAT",
    duong_dung: "ID_DUONG_DUNG_BYT",
    ten_duong_dung: "TEN_DUONG_DUNG_BYT",
    IS_YC_HOI_CHAN: "IS_YC_HOI_CHAN",
    hien_thi: "KHOA",
    trang_thai: "TRANG_THAI",
  };

  const fieldHangSanXuat = {
    id: "ID",
    ma_hang_sx: "MA_HANG_SX",
    ten_hang_sx: "TEN_HANG_SX",
    ghi_chu: "GHI_CHU",
    hien_thi: "KHOA",
    trang_thai: "TRANG_THAI",
  };

  const fieldQuocGia = {
    id: "ID",
    ma_quoc_gia: "MA_QUOC_GIA",
    ten_quoc_gia: "TEN_QUOC_GIA",
    ten_goi_khac: "TEN_GOI_KHAC",
    ten_goi_viet_tat: "TEN_VIET_TAT",
    hien_thi: "DANG_HOAT_DONG",
    trang_thai: "TRANG_THAI",
  };

  return (
    <div className={style["wrapper"]}>
      <div className={style["container"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>
        <div className={cn(style["inner"], style["form-thuoc"])}>
          <Row gutter={10} className={style["first-part"]}>
            {/* mã thuốc */}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.ma_thuoc}
                label={i18n.t(languageKeys.field_ma_thuoc)}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: i18n.t(languageKeys.vui_long_nhap),
                    // },
                  ]
                }
              >
                <Input placeholder={i18n.t(languageKeys.placeholder_Tao_tu_dong)} />
              </Form.Item>
            </Col>

            {/* mã vạch */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.ma_vach} label={i18n.t(languageKeys.field_ma_vach)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/* số đăng ký mới */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.so_dang_ky_thuoc} label={i18n.t(languageKeys.field_so_dang_ky_thuoc)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/* tên thuốc*/}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.ten_thuoc}
                label={i18n.t(languageKeys.field_ten_thuoc)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                name={fieldThuoc.ma_dvt}
                label={i18n.t(languageKeys.field_don_vi_tinh)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  dataSource={dsDonViTinh}
                  titleKey={fieldThuoc.ten_dvt}
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  // valueKey={fieldThuoc.ma_dvt}
                  showSearch
                  onSearch={handleSearchDonViTinh}
                  onDropdownVisibleChange={(bool) => bool && layDsDonViTinh()}
                  loading={loadingDonViTinh}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>

            {/* nhóm chi phí */}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.loai_thuoc}
                label={i18n.t(languageKeys.loai_thuoc)}
                rules={[{
                  required: true,
                  message: i18n.t(languageKeys.vui_long_nhap),
                }]}
                initialValue={"THUOC_THUONG"}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dataLoaiThuoc}
                  titleKey="name"
                  valueKey="key"
                  showSearch
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>

            {/* tên hoạt chất */}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.ma_hoat_chat}
                label={i18n.t(languageKeys.field_ten_hoat_chat)}
                rules={[
                  {
                    required: !!userProfile.config.DON_THUOC_CONG_DUOC,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  dataSource={hoatChat}
                  titleKey={fieldHoatChat.ten_hoat_chat}
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  onSearch={handleSearchHoatChat}
                  showSearch
                  onDropdownVisibleChange={(bool) => bool && layDsHoatChat()}
                  loading={loadingHoatChat}
                  disabled={disabled}
                  onAdd={() => hoatchatRef.current.open()}
                />
              </Form.Item>
            </Col>

            {/* nhóm thuốc */}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.ma_nhom_thuoc}
                label={i18n.t(languageKeys.field_nhom_thuoc)}
                rules={[
                  {
                    required: false,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                {/* <SelectTree
                  style={{ width: "100%" }}
                  onAdd={(data) => addRef.current.open(data)}
                  // onSelect={handleOpenModal}
                  dataSource={dsNhomThuoc}
                  titleKey={fieldThuoc.ten_nhom_thuoc}
                  loading={loadingNhomThuoc}
                  childrenKey="NHOM_CON"
                  onDropdownVisibleChange={(visible) => {
                    if (visible) {
                      layDsNhomThuoc();
                    }
                  }}
                  disabled={disabled}
                ></SelectTree> */}
                <Select
                  dataSource={dsNhomThuoc}
                  titleKey={fieldThuoc.ten_nhom_thuoc}
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  showSearch
                  onSearch={handleSearchNuocSx}
                  onDropdownVisibleChange={(bool) => bool && layDsNhomThuoc()}
                  loading={loadingNhomThuoc}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>

            {/*==============END ROWS 1*/}
            {/* Đơn vị tính */}


            {/* hàm lượng */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.ham_luong} label={i18n.t(languageKeys.field_ham_luong)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/* nồng độ */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.nong_do} label={i18n.t(languageKeys.field_nong_do)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {/* thể tích */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.the_tich} label={i18n.t(languageKeys.field_the_tich)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {/* nước sản xuất */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.ma_nsx} label={i18n.t(languageKeys.field_nuoc_san_xuat)}>
                <Select
                  dataSource={dsNuocSx}
                  titleKey={fieldQuocGia.ten_quoc_gia}
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  showSearch
                  onSearch={handleSearchNuocSx}
                  onDropdownVisibleChange={(bool) => bool && layDsQuocGia()}
                  loading={loadingNuocSx}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>

            {/* hãng sản xuất */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.ma_hsx} label={i18n.t(languageKeys.field_hang_san_xuat)}>
                <Select
                  dataSource={dsHangSx}
                  titleKey={fieldHangSanXuat.ten_hang_sx}
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  // valueKey="ID"
                  showSearch
                  onSearch={handleSearchHangSx}
                  onDropdownVisibleChange={(bool) => bool && layDsHangSx()}
                  loading={loadingHangSx}
                  dropdownMatchSelectWidth={400}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>

            {/*==============END dòng 2 thông tin cơ sở*/}

            {/* quy cách đóng gói */}
            <Col span={4}>
              <Form.Item name={fieldThuoc.quy_cach_dong_goi} label={i18n.t(languageKeys.field_quy_cach_dong_goi)}>
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={i18n.t(languageKeys.dvt_quy_doi)} name={fieldThuoc.DVT_QUY_DOI}>
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsDonViTinh}
                  valueKey={"ID_DVT"}
                  titleKey={"TEN_DVT"}
                  disabled={disabled}
                  showSearch
                  onDropdownVisibleChange={(bool) => bool && layDsDonViTinh()}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={i18n.t(languageKeys.sl_quy_doi)} name={fieldThuoc.SL_QUY_DOI}>
                <InputNumber
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* ATC Code */}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.atc_code}
                label={i18n.t(languageKeys.field_ATC_code)}
                rules={[
                  {
                    rules: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            {/* nhóm chi phí */}
            <Col span={4}>
              <Form.Item
                name={fieldThuoc.ma_nhom_chi_phi_bhyt}
                label={i18n.t(languageKeys.field_Nhom_chi_phi_bhyt)}
                initialValue={i18n.t(languageKeys.duoc_thuoc)}
              >
                <Input
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomBhyt}
                  titleKey="TEN_NHOM"
                  valueKey="ID"
                  showSearch
                  defaultValue={i18n.t(languageKeys.duoc_thuoc)}
                  readOnly
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                />
              </Form.Item>
            </Col>


            {/*==============END dòng 3 thông tin cơ sở ==============*/}
          </Row>

          {/*==============Phân Loại thuốc ==============*/}
          <Row>
            <Col span={8}>
              <div className={style["colums-thuoc"]}>
                <Row justify="space-between" align="middle" className={style["row-thuoc"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_thuoc_khang_sinh)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.thuoc_khang_sinh} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between" align="middle" className={style["row-thuoc"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_che_pham_y_hoc_co_truyen)}</span>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ marginBottom: "0px" }}
                      name={fieldThuoc.che_pham_y_hoc_co_truyen}
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={8}>
              <div className={style["colums-thuoc"]}>
                <Row justify="space-between" align="middle" className={style["row-thuoc"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_thuoc_tan_duoc)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.thuoc_tan_duoc} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle" className={style["row-thuoc"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_thuoc_ke_don)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.thuoc_ke_don} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF", fontWeight: "bold" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={8}>
              <div className={cn(style["colums-thuoc"], style["no-border-right"])}>
                <Row justify="space-between" align="middle" className={style["row-thuoc"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_vi_thuoc_y_hoc)}</span>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ marginBottom: "0px" }}
                      name={fieldThuoc.vi_thuoc_y_hoc_co_truyen}
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between" align="middle" className={style["row-thuoc"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_thuc_pham_chuc_nang)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.thuc_pham_chuc_nang} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/*=========nhập giá thuốc =========*/}
      <div className={style["container"]}>
        <h4>{i18n.t(languageKeys.field_Gia)}</h4>
        <div className={cn(style["inner"], style["form-thuoc"])}>
          <Row gutter={10} className={style["first-part"]}>
            <Col span={4}>
              <PriceInput form={form} name={fieldThuoc.gia_nhap} label={i18n.t(languageKeys.field_Gia_nhap)} />
            </Col>
            <Col span={4}>
              <PriceInput form={form} name={fieldThuoc.gia_ban} label={i18n.t(languageKeys.field_Gia_ban)} />
            </Col>
          </Row>
        </div>
      </div>

      {/*=========chọn tính năng khoá, tạm dừng nhập NCC =========*/}
      <div className={style["container"]}>
        <h4>{i18n.t(languageKeys.field_tinh_nang)}</h4>
        <div className={cn(style["inner"], style["form-thuoc"])}>
          <Row>
            <Col span={6}>
              <div className={style["colums-thuoc"]}>
                <Row justify="space-between" align="middle" className={style["row-tinh-nang"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_cho_phep_mien_giam)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.cho_phep_mien_giam} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={6}>
              <div className={style["colums-thuoc"]}>
                <Row justify="space-between" align="middle" className={style["row-tinh-nang"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.cho_phep_ban_gia_thang_du)}</span>
                  </Col>
                  <Col>
                    <Form.Item
                      style={{ marginBottom: "0px" }}
                      name={fieldThuoc.cho_phep_ban_gia_thang_du}
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={6}>
              <div className={style["colums-thuoc"]}>
                <Row justify="space-between" align="middle" className={style["row-tinh-nang"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_Hien_thi)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.hien_thi} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={6}>
              <div className={style["colums-thuoc"]}>
                <Row justify="space-between" align="middle" className={style["row-tinh-nang"]}>
                  <Col>
                    <span className={style["lable-thuoc"]}>{i18n.t(languageKeys.field_tam_dung_nhap_NCC)}</span>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: "0px" }} name={fieldThuoc.tam_dung_nhap_NCC} valuePropName="checked" initialValue={false}>
                      <Switch
                        checkedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Bat)}</span>}
                        unCheckedChildren={<span style={{ color: "#FFF" }}>{i18n.t(languageKeys.common_Tat)}</span>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <CustomModal ref={addRef} title={i18n.t(languageKeys.title_them_moi_nhom_thuoc)} dsNhom={dsNhomThuoc} />

      <CaiDatDanhMuc
        currentKey={keysDanhMuc?.dm_hoat_chat || "DM_HOAT_CHAT"}
        ref={hoatchatRef}
        onAddSubmitSuccess={(res) => {
          layDsHoatChat(res?.data?.TEN_HOAT_CHAT)
          hoatchatRef.current?.close()
          form.setFieldValue(fieldThuoc.ma_hoat_chat, JSON.stringify(res?.data))
        }}
        disableThemMoi={false}
      />

    </div>
  );
};

export default ThemMoiThuoc;
