import moment from "moment";
import React, { useEffect, useState } from "react";
import { layDiaChi, getSexFromString, rid, common_post } from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";
import { isArray } from "lodash";
import { apis } from "constants/index";

export const styleTheoDoiTruyenDich = `

.title {
  font-size: 10px;
}
#theo-doi-truyen-dich * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#theo-doi-truyen-dich .column {
  float: left;
  width: 33.33%;
}

#theo-doi-truyen-dich .row:after {
  content: "";
  display: table;
  clear: both;
}

#theo-doi-truyen-dich .container-row {
  margin-top: 8px;
}

#theo-doi-truyen-dich .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#theo-doi-truyen-dich .header { display: flex; gap: 10px; }
#theo-doi-truyen-dich .flex {
    display: flex;
  }
#theo-doi-truyen-dich .flex.justify {
    justify-content: space-around;
  }

#theo-doi-truyen-dich .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #theo-doi-truyen-dich .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #theo-doi-truyen-dich td, #theo-doi-truyen-dich th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #theo-doi-truyen-dich .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#theo-doi-truyen-dich .tel {
    margin-right: 50px;
  }
#theo-doi-truyen-dich .txt-700 {
    font-weight: 700;
  }
#theo-doi-truyen-dich .container-title {
  text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#theo-doi-truyen-dich .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#theo-doi-truyen-dich .container__ttbn--wrapper {
  margin-top: 6px;
}
#theo-doi-truyen-dich .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#theo-doi-truyen-dich .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #theo-doi-truyen-dich .container-wrapper-flex .col-3{
    width: 30%;
  }

  #theo-doi-truyen-dich .container-wrapper-flex .col-4{
    width: 40%;
  }

  #theo-doi-truyen-dich .container-wrapper-flex .col-5{
    width: 50%;
  }
  #theo-doi-truyen-dich .container-wrapper-flex .col-7{
    width: 70%;
  }
  #theo-doi-truyen-dich .container-wrapper-flex .col-33{
    width: 33.3%;
  }

  #theo-doi-truyen-dich .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #theo-doi-truyen-dich .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  #theo-doi-truyen-dich .thongtin-khambenh .right {
    width: 35%;
  }

  #theo-doi-truyen-dich .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #theo-doi-truyen-dich .table-item{
    padding-left:12px;
  }

  #theo-doi-truyen-dich hr.solid {
    border-top: 1px solid #000;
  }

  #theo-doi-truyen-dich .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
  }

  #theo-doi-truyen-dich .footer .footer-confirm {
    margin-right: 35px;
    text-align: center;
  }

  #theo-doi-truyen-dich .name-space {
    height: 80px;
  }

  #theo-doi-truyen-dich .txt-size-m{
    font-size: 12px;
  }

  #theo-doi-truyen-dich .upperTxt{
    text-transform: uppercase;
  }

  #theo-doi-truyen-dich .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#theo-doi-truyen-dich .foot-break { break-inside: avoid; }
}

#theo-doi-truyen-dich .table { margin-block: 10px; }
#theo-doi-truyen-dich .table * { font-size: 12px; }
#theo-doi-truyen-dich .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#theo-doi-truyen-dich .table th, .table td { padding: 5px 10px; }
#theo-doi-truyen-dich .table th { text-align: start; }
`;
export const styleTheoDoiTruyenDichGL = `

.title {
  font-size: 16pt;
}
#theo-doi-truyen-dich * { font-size: 14pt;  font-family: "Times New Roman", Times, serif; }

#theo-doi-truyen-dich .column {
  float: left;
  width: 33.33%;
}

#theo-doi-truyen-dich .row:after {
  content: "";
  display: table;
  clear: both;
}

#theo-doi-truyen-dich .container-row {
  margin-top: 8px;
}

#theo-doi-truyen-dich .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#theo-doi-truyen-dich .header { display: flex; gap: 10px; }
#theo-doi-truyen-dich .flex {
    display: flex;
  }
#theo-doi-truyen-dich .flex.justify {
    justify-content: space-around;
  }

#theo-doi-truyen-dich .text-bold {
    font-size: 14pt;
    font-weight: bold !important;
  }

  #theo-doi-truyen-dich .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #theo-doi-truyen-dich td, #theo-doi-truyen-dich th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #theo-doi-truyen-dich .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#theo-doi-truyen-dich .tel {
    margin-right: 50px;
  }
#theo-doi-truyen-dich .txt-700 {
    font-weight: 700;
  }
#theo-doi-truyen-dich .container-title {
  text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#theo-doi-truyen-dich .container__title--main {
    font-weight: 700;
    font-size: 14pt;
    line-height: 16pt;
  }
#theo-doi-truyen-dich .container__ttbn--wrapper {
  margin-top: 6px;
}
#theo-doi-truyen-dich .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#theo-doi-truyen-dich .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #theo-doi-truyen-dich .container-wrapper-flex .col-3{
    width: 30%;
  }

  #theo-doi-truyen-dich .container-wrapper-flex .col-4{
    width: 40%;
  }

  #theo-doi-truyen-dich .container-wrapper-flex .col-5{
    width: 50%;
  }
  #theo-doi-truyen-dich .container-wrapper-flex .col-33{
    width: 33.3%;
  }

  #theo-doi-truyen-dich .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #theo-doi-truyen-dich .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  #theo-doi-truyen-dich .thongtin-khambenh .right {
    width: 35%;
  }

  #theo-doi-truyen-dich .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #theo-doi-truyen-dich .table-item{
    padding-left:12px;
  }

  #theo-doi-truyen-dich hr.solid {
    border-top: 1px solid #000;
  }

  #theo-doi-truyen-dich .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
  }

  #theo-doi-truyen-dich .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #theo-doi-truyen-dich .name-space {
    height: 80px;
  }

  #theo-doi-truyen-dich .txt-size-m{
    font-size: 14pt;
  }

  #theo-doi-truyen-dich .upperTxt{
    text-transform: uppercase;
  }

  #theo-doi-truyen-dich .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#theo-doi-truyen-dich .foot-break { break-inside: avoid; }
}

#theo-doi-truyen-dich .table { margin-block: 10px; }
#theo-doi-truyen-dich .table * { font-size: 14pt; }
#theo-doi-truyen-dich .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#theo-doi-truyen-dich .table th, .table td { padding: 5px 10px; }
#theo-doi-truyen-dich .table th { text-align: start; }
`;

const PhieuTheoDoiTruyenDich = ({ data }) => {

  const userProfile = useSelector((state) => state.auth.user);
  let { benh_nhan, dien_bien_tinh_trang, danh_sach_vat_tu, vat_tu_su_dung } = data;
  const [arrayImage, setArrayImage] = useState([])


  const getImageFromLink = async (array) => {
    if (isArray(array) && array?.length > 0) {
      try {
        const response = await common_post(apis.layFileS3, { fileNames: array });
        if (response && response?.status == "OK" && response?.result?.length > 0) {

          let { result } = response
          let data = result.map((demo, index) => ({
            isOld: true,
            url: demo,
            fileKey: array[index]
          }))
          setArrayImage(data || [])
        }
      } catch (error) {
        console.error("Error converting image to Base64:", error);
      }

    } else {
      setArrayImage([])
    }

  }

  useEffect(() => {
    try {
      let dataImage = JSON.parse(dien_bien_tinh_trang?.hinh_anh) || []
      getImageFromLink(dataImage)
    } catch (error) {}

  }, [dien_bien_tinh_trang])




  return (
    <div id="theo-doi-truyen-dich">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN} />
          </div>
        )}
        <div className="content-header">
          <h2 style={{ margin: 0 }} className="upperTxt title">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
          </div>
          <div>
            <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="container-title">
          {i18n.t(languageKeys.phieu_benh_dieu_tri)}
        </div>
        <div className="container__chapter--1">
          <div className="container__title--main">I. {i18n.t(languageKeys.field_Thong_tin_hanh_chinh)?.toUpperCase()}</div>
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ma_benh_nhan)}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ho_ten)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ten_benh_nhan)?.toUpperCase()}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_So_dien_thoai)}: <span className="txt-700">{convertString(benh_nhan.sdt)}</span>
                </div>
              </div>
              <div className="col-3">
                <div>
                  {benh_nhan.ngay_sinh?.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}: {benh_nhan.ngay_sinh}
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {convertString(benh_nhan.dia_chi)?.slice(-1) === ","
              ? convertString(benh_nhan.dia_chi).slice(0, -1).replaceAll(",", ", ")
              : convertString(benh_nhan.dia_chi).replaceAll(",", ", ")
            }
          </div>
          <div className="container-wrapper-flex">
            <div className="col-5">
              <div>
                {i18n.t(languageKeys.field_Phong_thuc_hien)}: {convertString(data.phong_thuc_hien)}
              </div>
              <div>
                {i18n.t(languageKeys.thoi_gian_bat_dau)}:
                {data.gio_bat_dau ? `${convertString(data.gio_bat_dau)} - ${moment(data.ngay_bat_dau, "YYYYMMDD").format("DD/MM/YYYY")}` : ""}
              </div>
              <div>
                {i18n.t(languageKeys.nguoi_thuc_hien_chinh)}: {data.thanh_phan_tham_gia.find(i => i.PHAN_LOAI === "TP_CHINH")?.HO_TEN_NHAN_SU}
              </div>
            </div>
            <div className="col-5">
              <div>
                {i18n.t(languageKeys.goi_dich_vu)}: {convertString(data.ten_goi_dv)}
              </div>
              <div>
                {i18n.t(languageKeys.thoi_gian_ket_thuc)}:
                {data.gio_ket_thuc ? `${convertString(data.gio_ket_thuc)} - ${moment(data.ngay_ket_thuc, "YYYYMMDD").format("DD/MM/YYYY")}` : ""}
              </div>
              <div>
                {i18n.t(languageKeys.nguoi_ho_tro)}: {data.thanh_phan_tham_gia.filter(i => i.PHAN_LOAI !== "TP_CHINH").flatMap(i => i.HO_TEN_NHAN_SU).join(", ")}
              </div>
            </div>
          </div>
        </div>

        <div className="container__title--main" style={{ margin: "10px 0" }}>
          II. {i18n.t(languageKeys.dich_truyen_vat_tu).toUpperCase()}
        </div>
        {danh_sach_vat_tu?.[0] && (
          <table style={{ width: "100%" }} className="table">
            <thead>
              <tr>
                <th style={{ width: 28, textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ten_thuoc_vat_tu)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_ham_luong)}, {i18n.t(languageKeys.field_nong_do).toLowerCase()}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.answer_co)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.answer_khong)}</th>
                <th style={{ width: 28, textAlign: 'center' }}>{i18n.t(languageKeys.sl)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.duong_dung)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.toc_do_truyen)}</th>
              </tr>
            </thead>
            <tbody>
              {/* {danh_sach_vat_tu.map((item, index) => {
                const vtsd = vat_tu_su_dung.find(i => i.VAT_TU_TRONG_KHO_ID === item.VAT_TU_TRONG_KHO_ID)
                return (
                  <tr key={rid()}>
                    <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                    <td>{item.TEN_VAT_TU}</td>
                    <td>{item.HAM_LUONG || item.NONG_DO}</td>
                    <td style={{ textAlign: 'center' }}>{vtsd ? "✔" : ""}</td>
                    <td style={{ textAlign: 'center' }}>{vtsd ? "" : "✔"}</td>
                    <td style={{ textAlign: 'center' }}>{vtsd ? vtsd.SO_LUONG : ""}</td>
                    <td>{vtsd ? vtsd.DUONG_DUNG : item.DUONG_DUNG}</td>
                    <td>{vtsd ? vtsd.TOC_DO_TRUYEN : item.TOC_DO_TRUYEN}</td>
                  </tr>
                );
              })} */}
              {vat_tu_su_dung
                .filter(i => i.VAT_TU_TRONG_KHO_ID)
                .map((item, index) => (
                  <tr key={rid()}>
                    <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                    <td>{item.TEN_VAT_TU}</td>
                    <td>{item.HAM_LUONG || item.NONG_DO}</td>
                    <td />
                    <td />
                    <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                    <td>{item.DUONG_DUNG}</td>
                    <td>{item.TOC_DO_TRUYEN}</td>
                  </tr>
                ))}
              <tr key={rid()} style={{ height: 30 }}>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr key={rid()} style={{ height: 30 }}>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr key={rid()} style={{ height: 30 }}>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        )}
        <div style={{ height: 45 }}>
          {i18n.t(languageKeys.ghi_chu)}: {convertString(data.ghi_chu)}
        </div>

        <div className="thongtin-khambenh" style={{ display: "flex", flexDirection: "column" }}>
          <div className="container__title--main">III. {i18n.t(languageKeys.dien_bien_tinh_trang_khach_hang).toUpperCase()}</div>
          <div className="container-row">
            <div className="row">
              <div>
                <div className="txt-700">1. {i18n.t(languageKeys.truoc_thuc_hien)}</div>
                <div className="container-wrapper-flex">
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Nhiet_do)}: {dien_bien_tinh_trang.nhiet_do_truoc_thuc_hien ? convertString(dien_bien_tinh_trang.nhiet_do_truoc_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} {i18n.t(languageKeys.field_Do_C)}
                  </div>
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Mach)}: {dien_bien_tinh_trang.mach_truoc_thuc_hien ? convertString(dien_bien_tinh_trang.mach_truoc_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} {i18n.t(languageKeys.field_Lan_phut)}
                  </div>
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Huyet_ap)}: {dien_bien_tinh_trang.huyet_ap_truoc_thuc_hien ? convertString(dien_bien_tinh_trang.huyet_ap_truoc_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} mmHg
                  </div>
                </div>
                <div style={{ height: 45 }}>
                  {i18n.t(languageKeys.mo_ta_chi_tiet)}: {convertString(dien_bien_tinh_trang.mo_ta_chi_tiet_truoc_thuc_hien)}
                </div>
              </div>
              <div style={{ marginTop: '6px' }}>
                <div className="txt-700">2. {i18n.t(languageKeys.trong_khi_thuc_hien)}</div>
                <div className="container-wrapper-flex">
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Nhiet_do)}: {dien_bien_tinh_trang.nhiet_do_trong_thuc_hien ? convertString(dien_bien_tinh_trang.nhiet_do_trong_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} {i18n.t(languageKeys.field_Do_C)}
                  </div>
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Mach)}: {dien_bien_tinh_trang.mach_trong_thuc_hien ? convertString(dien_bien_tinh_trang.mach_trong_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} {i18n.t(languageKeys.field_Lan_phut)}
                  </div>
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Huyet_ap)}: {dien_bien_tinh_trang.huyet_ap_trong_thuc_hien ? convertString(dien_bien_tinh_trang.huyet_ap_trong_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} mmHg
                  </div>
                </div>
                <div style={{ height: 45 }}>
                  {i18n.t(languageKeys.mo_ta_chi_tiet)}: {convertString(dien_bien_tinh_trang.mo_ta_chi_tiet_trong_thuc_hien)}
                </div>
              </div>
              <div style={{ marginTop: '6px' }}>
                <div className="txt-700">3. {i18n.t(languageKeys.sau_khi_thuc_hien)}</div>
                <div className="container-wrapper-flex">
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Nhiet_do)}: {dien_bien_tinh_trang.nhiet_do_sau_thuc_hien ? convertString(dien_bien_tinh_trang.nhiet_do_sau_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} {i18n.t(languageKeys.field_Do_C)}
                  </div>
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Mach)}: {dien_bien_tinh_trang.mach_sau_thuc_hien ? convertString(dien_bien_tinh_trang.mach_sau_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} {i18n.t(languageKeys.field_Lan_phut)}
                  </div>
                  <div className="col-33">
                    {i18n.t(languageKeys.field_Huyet_ap)}: {dien_bien_tinh_trang.huyet_ap_sau_thuc_hien ? convertString(dien_bien_tinh_trang.huyet_ap_sau_thuc_hien) : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} mmHg
                  </div>
                </div>
                <div>
                  {i18n.t(languageKeys.mo_ta_chi_tiet)}: {convertString(dien_bien_tinh_trang.mo_ta_chi_tiet_sau_thuc_hien)}
                </div>
              </div>
            </div>
          </div>

          <div className="container__title--main" style={{ margin: "10px 0 0" }}>
            IV. {i18n.t(languageKeys.danh_sach_hinh_anh).toUpperCase()}
          </div>
          <div className="container-row" style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
            {
              arrayImage?.length > 0 && arrayImage.map(obj => <img src={obj?.url} style={{ width: "18%" }} alt="" />)
            }
          </div>

        </div>
      </div>

      <div className="footer foot-break">

        <div style={{ marginTop: 16, textAlign: 'center', marginLeft: 35 }}>
          <div className="txt-700">{i18n.t(languageKeys.khach_hang)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{benh_nhan.ten_benh_nhan}</div>
        </div>

        <div style={{ marginTop: 16, textAlign: 'center' }}>
          <div className="txt-700">{i18n.t(languageKeys.dieu_duong)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
        </div>

        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">{i18n.t(languageKeys.field_Bac_si)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          {/* <div className="txt-700">{data.bac_si_ket_luan}</div> */}
        </div>

      </div>
    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuTheoDoiTruyenDich;
