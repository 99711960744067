import { memo, useCallback, useEffect, useState } from "react";
import formDanhMuc from "./formDanhMuc.module.less";
import { Button, Col, Form, Input, notification, Row, Tabs } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { useSelector } from "react-redux";
import { getErrorMessage, HLog, isEmptyObject } from "../../../../helpers";
import { debounce } from "lodash";
import { useForm } from "antd/lib/form/Form";
import { apiSuaThuoc, apiThemThuoc } from "../apisDanhMuc/apisDmThuoc";
import LichSuNhapThuoc from "../../../../components/LichSuNhapThuoc.js/LichSuNhapThuoc";
import { useEventListener } from "../../../../hooks";
import { SearchOutlined } from "@ant-design/icons";
import ThemMoiThuoc from "pages/QuanLyDuocNgoaiTru/components/ThemMoiThuoc/ThemMoiThuoc";

const FormThuoc = ({
  initData = {},
  visible = false,
  // isCreateNew = false,
  doAfterSubmitThuoc = () => {},
  callBackData = () => {},
  closeModal = () => {}
}) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [tabActive, setTabActive] = useState("1")
  const [currentData, setCurrentData] = useState({})
  const [searchString, setSearchString] = useState("")
  const [loading, setLoading] = useState(false)
  const [form] = useForm()
  const config = userProfile.config;
  const isKhoThuoc = config && config.KHO_THUOC || false;

  useEffect(() => {
    // console.log("initDatainitData", initData)
    // if (visible && !isEmptyObject(initData)) {
    //   setCurrentData(initData)
    // }
    if (!visible) {
        form.resetFields()
        setCurrentData({})
        setTabActive("1")

    }
  }, [visible]);


   //hàm xử lí khi sửa, tạo mới thuốc
   const handleSubmit = async (values) => {
    HLog("ModalThongTinChiTiet onSuaThuoc values: ", values);
    setLoading(true)
    if (isEmptyObject(initData)) {
      //nếu là thêm mới thuốc
      HLog(
        "ModalThongTinChiTiet apiThemThuoc values: " + JSON.stringify(values)
      );
      let body = {
        data: values,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      };
      // await apiThemThuoc({ ...body });
      const res=await apiThemThuoc({ ...body });
      if (res.status==="OK") {
        notification.success({
          message: i18n.t(languageKeys.them_moi_thanh_cong),
          placement: "bottomLeft",
        });
        closeModal()
        doAfterSubmitThuoc();
      } else {
        const fallback = i18n.t(languageKeys.cap_nhat_that_bai);
        let error_msg = getErrorMessage(res.error_code, fallback);
        notification.error({
          message: error_msg,
          placement: "bottomLeft",
        });
        form.setFields([
          {
            name: res.field,
            errors: [""],
          },
        ]);
      }


    } else {
      //nếu là sửa thuốc
      const data = {
        ...initData,
        ...values,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      };
      const response= await apiSuaThuoc({ data, currentData: initData });
      // console.log(await apiSuaThuoc({ data, currentData: initData }),"yoyo")

      if (response.status==="OK") {
        notification.success({
          message: i18n.t(languageKeys.cap_nhat_thanh_cong),
          placement: "bottomLeft",
        });
        closeModal()
        doAfterSubmitThuoc();
      } else {
        const fallback = i18n.t(languageKeys.cap_nhat_that_bai);
        let error_msg = getErrorMessage(response.error_code, fallback);
        notification.error({
          message: error_msg,
          placement: "bottomLeft",
        });
        form.setFields([
          {
            name: response.field,
            errors: [""],
          },
        ]);
      }

    }
    setLoading(false)


  };

  const  handleChangeSearch = useCallback(debounce((txt) => {
    setSearchString(txt)
  },1000),[])



  const keyDownEvent = (event) => {
    if(event.ctrlKey && event.key ==='s'){
      event.stopPropagation();
      event.preventDefault();
      form.submit()
    }
  }
  useEventListener('keydown',keyDownEvent,window.document,tabActive === "1" && visible)
  return (
    <>
      <div className={formDanhMuc["formThuoc"]}>
        <Tabs
            defaultActiveKey="1"
            className={formDanhMuc["tab-ctn"]}
            activeKey={tabActive}
            onChange={(value) => setTabActive(value)}
            tabBarExtraContent={
              tabActive === "2" && <Input
              prefix = {<SearchOutlined style={{fontSize : "20px", color : "#6576FF"}}/>}
              className={formDanhMuc["input-search"]}
              placeholder={i18n.t(languageKeys.common_Tim_kiem)}
              onChange ={(e) => handleChangeSearch(e.target.value)}
              allowClear
            />
            }
          >
            <Tabs.TabPane tab={i18n.t(languageKeys.thong_tin)} key="1">
              <Form
                  layout="vertical"
                  form={form}
                  // className={formDanhMuc["wrapper"]}
                  onFinish={(value) => {
                    handleSubmit(value);
                  }}
                  style={{backgroundColor : "#FFF"}}
                >
                  <ThemMoiThuoc
                    form={form}
                    visible={visible}
                    initData={initData}
                    callBackData={callBackData}
                  />
                  <Row justify="end" gutter={10} align="middle" style={{marginRight :  "20px", paddingBottom : "20px"}}>
                      <Col>
                        <Button onClick={closeModal} type="primary" ghost >{i18n.t(languageKeys.common_Thoat)} (ESC)</Button>
                      </Col>
                      <Col>
                        <Button onClick={() => form.submit()} type="primary" loading={loading}>{i18n.t(languageKeys.common_Luu)} (Ctrl + S)</Button>
                      </Col>
                  </Row>
                </Form>
            </Tabs.TabPane>
              {!isEmptyObject(initData) && isKhoThuoc && <Tabs.TabPane tab={i18n.t(languageKeys.lich_su_nhap_thuoc)} key="2">
                  <LichSuNhapThuoc dataThuoc = {initData} searchString={searchString} onClose = {() => closeModal()}/>
              </Tabs.TabPane>}
          </Tabs>

      </div>
    </>
  );
};

export default memo(FormThuoc);
