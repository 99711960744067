import React, { useState, useEffect, useContext, useRef } from "react";
import { Input, Layout, List, Row, Col, Button, Space, Form, Typography } from "antd";
import styles from "./sider.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { BarcodeOutlined, SearchOutlined } from "@ant-design/icons";
import { Select } from "../../../../components_v2";
import FilterDate from "../../../QuanLyVienPhi/DichVuCanThanhToan/components/FilterDate";
import cn from "classnames";
import {
  convertDateToValue,
  convertMillisecondToAge,
  convertMillisecondToAgeNotT,
  convertValueToMilisecond,
  getSexFromString,
  HLog,
  localSet,
  rid,
} from "../../../../helpers";
import { apis, keys } from "../../../../constants";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { apiLayDsPhieuChiDinh, apiLayDsPhong } from "../services";
import { TraKQContext } from "../TraKetQuaCdha";
import { useForm } from "antd/lib/form/Form";
import Constants from "../../../../constants/Constants";
import { doReload, doReloadKq, resetRisMessage, updateUncheckListNoti } from "../../../../ducks/slices/risReloadSilce";
import _, { isArray } from "lodash";
import { useMemo } from "react";
import { ComplexDatePicker } from "components";
import { notiListKey } from "constants/keys";
import { Calendar2, Note2, RecepIcon } from "assets/svg";

export const tabItemsKey = {
  CHO_THUC_HIEN: "CHO_THUC_HIEN",
  DANG_THUC_HIEN: "DANG_THUC_HIEN",
  DA_TRA_KET_QUA: "DA_TRA_KET_QUA",
};

const tabItemTitle = {
  CHO_THUC_HIEN: i18n.t(languageKeys.cho_TH),
  DANG_THUC_HIEN: i18n.t(languageKeys.dang_TH),
  DA_TRA_KET_QUA: i18n.t(languageKeys.btn_da_tra_kq),
};

var _eventSource = null;

function Sider() {
  const { setSelectedPhong, setReloadAfterThucHien, reloadAfterThucHien, selectedPhong } = useContext(TraKQContext);
  const { unCheckListNoti } = useSelector(((state) => state.risReloadSilce));

  const refFilterDate = useRef();
  const userProfile = useSelector((state) => state.auth.user);
  const message = useSelector((state) => state.risReloadSilce.message);
  const dispatch = useDispatch();
  const [form] = useForm();
  const [dsPhieu, setDsPhieu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [selectedPhongId, setSelectedPhongId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotlaPage] = useState(1);
  const [dsPhong, setDsPhong] = useState([]);
  //const [mounted, setMounted] = useState(false)
  const [dataRequest, setDataRequest] = useState({
    DEN_NGAY: moment().format("YYYYMMDD"),
    TU_NGAY: moment().format("YYYYMMDD"),
    search_string: "",
  });

  const [demSoLuong, setDemSoLuong] = useState({
    CHO_THUC_HIEN: 0,
    DANG_THUC_HIEN: 0,
    DA_TRA_KET_QUA: 0,
  });

  const { selectedPhieu, setSelectedPhieu, tabActive, setTabActive } = useContext(TraKQContext);

  useEffect(() => {
    // setMounted(true)
    // layDsBenhNhan({})
    layDsPhong();
  }, []);

  useEffect(() => {
    if (reloadAfterThucHien) {
      setReloadAfterThucHien(false);
      layDsPhong("", true);
    }
  }, [reloadAfterThucHien])


  useEffect(() => {
    if (message === "RELOAD") {
      dispatch(resetRisMessage());
      layDsBenhNhan(dataRequest);
      layDsPhong()
    }
  }, [message]);

  useEffect(() => {
    let searchParams = new URL(window.location).searchParams;
    if (searchParams.get("RIS") == keys.openForm) {
      setTabActive((curr) => {
        if (curr != tabItemsKey.DANG_THUC_HIEN) {
          return tabItemsKey.DANG_THUC_HIEN;
        } else {
          return curr;
        }
      });
    }
  }, [window.location]);


  // khi tab thay đổi
  useEffect(() => {
    // refFilterDate.current.resetFilter()
    // searchString && setSearchString("")

    if (selectedPhongId) {
      let initReq = {
        ...dataRequest,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        page: 1,
        limit: keys.limit,
        PHONG_ID: isArray(selectedPhongId) ? selectedPhongId : [selectedPhongId],
        TRANG_THAI: tabActive,
      };
      layDsBenhNhan(initReq);
      // if (!isArray(selectedPhongId)) {
      //   initEventSource(selectedPhongId, initReq);
      // } else {
      // initEventSource(selectedPhongId, initReq);
      // }
      return () => {
        _eventSource && _eventSource.close?.();
      }
    }
  }, [tabActive, selectedPhongId, selectedPhong]);
  // }, [tabActive, selectedPhongId,selectedPhieu,selectedPhong]);

  // useEffect(() => {
  //    if (tabActive === tabItemsKey.CHO_THUC_HIEN){
  //        initEventSource(selectedPhongId, dataRequest)
  //    }
  // }, [dataRequest]);

  //hàm khởi tạo eventsource để lắng nghe sự kiện thay đổi cuộc hẹn
  const initEventSource = (phongID, req) => {
    let hospital_id = userProfile.BENH_VIEN_ID;
    let partner_code = userProfile.partner_code;
    // let urlEvent = apis.event_reload_ris + "?BENH_VIEN_ID=" + hospital_id + "&partner_code=" + partner_code + "&PHONG_ID=" + phongID;
    let urlEvent = apis.event_reload_ris + "?BENH_VIEN_ID=" + hospital_id + "&partner_code=" + partner_code;

    _eventSource = new EventSource(urlEvent);
    _eventSource.onmessage = (e) => {
      HLog("Sider initEventSource onmessage: ", JSON.parse(e.data));
    };

    _eventSource.addEventListener("responseReloadRIS", (event) => {
      HLog("Sider initEventSource addEventListener update:", JSON.parse(event.data));
      HLog("event riss: ", event.data);
      if (event.data && event.data !== "") {
        let dataEvent = JSON.parse(event.data);
        let { type } = dataEvent;
        if (type === Constants.CAP_NHAT_CHI_DINH_RIS && tabActive === tabItemsKey.CHO_THUC_HIEN) {
          if (typeof selectedPhong === "string") {
            if (!!selectedPhong && (dataEvent.PHONG_ID == selectedPhong)) {
              dispatch(doReload());
            }
          } else if (!!Array.isArray(selectedPhong)) {
            dispatch(doReload());
          }
          //layDsBenhNhan(req, true)
        }

        // reload thanh notifications
        if ([Constants.NHAN_KQ_CDHA_VIN, Constants.NHAN_KQ_CDHA_CHI_DINH_VIN, Constants.AI_DA_PHAN_TICH].includes(type)) {
          let formatEvent = { ...dataEvent };
          let phieu = selectedPhieu;
          dispatch(updateUncheckListNoti([formatEvent]));

          if (type == Constants.AI_DA_PHAN_TICH) {
            return true;
          }
          try {
            if (!!selectedPhieu && typeof selectedPhieu === "string") {
              phieu = JSON.parse(selectedPhieu)
            }
          } catch (error) {
            phieu = {};
          }
          if (typeof selectedPhong === "string") {

            if (!!selectedPhong && (dataEvent.PHONG_ID == selectedPhong) && (!!phieu && dataEvent.PHIEU_ID == phieu.PHIEU_ID)) {

              dispatch(doReloadKq());
            }
          } else if (!!Array.isArray(selectedPhong) && (!!phieu && dataEvent.PHIEU_ID == phieu.PHIEU_ID)) {
            dispatch(doReloadKq());
          }
        }
      }
    });
  };

  // lấy danh sách bệnh nhân
  async function layDsBenhNhan(req) {
    if (req.search_string.length < keys.limit_min_length && req.search_string.length > 0) {
      return;
    }
    setLoading(true);
    if (JSON.stringify(req) !== JSON.stringify(dataRequest)) {
      setDataRequest(req);
    }
    let response = await apiLayDsPhieuChiDinh(req);
    if (response) {
      let { current_page, total_page, result, count } = response;

      setTotlaPage(total_page);
      setDemSoLuong({
        CHO_THUC_HIEN: count?.COUNT_CHO_THUC_HIEN || 0,
        DANG_THUC_HIEN: count?.COUNT_DANG_THUC_HIEN || 0,
        DA_TRA_KET_QUA: count?.COUNT_HOAN_THANH || 0,
      })
      // if (req.page > currentPage){ // khi mà page khác page hiện tại
      //    if (selectedPhieu && !dsPhieu.some(item => item.PHIEU_ID === selectedPhieu.PHIEU_ID) && !result.some(item => item.PHIEU_ID === selectedPhieu.PHIEU_ID && !searchString)) {
      //        setDsPhieu([selectedPhieu].concat(result, dsPhieu));
      //    }else {
      //        setDsPhieu(result.concat(dsPhieu))
      //    }
      // } else{
      //     let arr =  Array.from(new Set(dsPhieu.concat(result)));
      //     if (selectedPhieu && !result.some(item => item.PHIEU_ID === selectedPhieu.PHIEU_ID) && !searchString){ //nếu không có phếu hiện tai trong danh sách  ỡ, ...ư
      //         setDsPhieu([selectedPhieu].concat(arr))
      //     }else {
      //         setDsPhieu(arr)
      //     }
      // }
      setDsPhieu(req.page > currentPage ? dsPhieu.concat(result) : result);
      setCurrentPage(current_page);
      setLoading(false);
      if (!selectedPhieu && result.length > 0) {
        let searchParams = new URL(window.location).searchParams;

        let phieu_id = searchParams.get("PHIEU_ID")
        if (!phieu_id) {
          setSelectedPhieu(result[0]);
        }
        if (result.length && phieu_id) {
          let findPhieu = result.find(i => i.PHIEU_ID == phieu_id);
          if (!!findPhieu) {
            setSelectedPhieu(findPhieu);
          }
        }
      }
    } else {
      setLoading(false);
      setDsPhieu([]);
      setDemSoLuong({
        CHO_THUC_HIEN:  0,
        DANG_THUC_HIEN: 0,
        DA_TRA_KET_QUA:  0,
      })
    }
  }

  // lấy danh sách phòng
  async function layDsPhong(search_string = "", isReload = false) {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      NHANSU_ID: userProfile.NHANSU_ID,
      limit: 10000,
      DEN_NGAY: dataRequest?.DEN_NGAY,
      TU_NGAY: dataRequest?.TU_NGAY,
      search_string: dataRequest?.search_string?.trim(),
    };
    const response = await apiLayDsPhong(req);
    if (response) {
      let arr = response.result.map((item) => ({ ID: item.ID, TEN_KHOA_PHONG: item.TEN_KHOA_PHONG, SO_CHO_KHAM: item.SO_CHO_KHAM, TONG_SO_PHIEU: item.TONG_SO_PHIEU }));
      if (!selectedPhong) {
        let dataPhong = localStorage.getItem(keys.ris_phong_da_chon);
        setDsPhong(c => !!arr.length ? [...arr, {
          TEN_KHOA_PHONG: i18n.t(languageKeys.tat_ca),
          ID: "TAT_CA",
          SO_CHO_KHAM: arr.reduce((total, item) => total += (item.SO_CHO_KHAM || 0), 0),
          TONG_SO_PHIEU: arr.reduce((total, item) => total += (item.TONG_SO_PHIEU || 0), 0)
        }] : c);
        if (!!dataPhong) {
          const valuePhong = response.result.find(r => r.ID == JSON.parse(dataPhong).ID);
          form.setFields([
            {
              name: "PHONG",
              value: JSON.stringify(valuePhong),
            },
          ]);
          setSelectedPhongId(JSON.parse(dataPhong).ID);
          setSelectedPhong(JSON.parse(dataPhong).ID);
        } else {
          if (arr.length == 1) {
            handleSelectePhong(JSON.stringify(arr[0]), arr);
          } else if (arr.length > 1) {
            handleSelectePhong(JSON.stringify({
              TEN_KHOA_PHONG: i18n.t(languageKeys.tat_ca),
              ID: "TAT_CA",
              SO_CHO_KHAM: arr.reduce((total, item) => total += (item.SO_CHO_KHAM || 0), 0),
              TONG_SO_PHIEU: arr.reduce((total, item) => total += (item.TONG_SO_PHIEU || 0), 0)
            }), arr);
          }
        }
      } else {
        if (isArray(selectedPhong) && selectedPhong.length > 0) {
          setDsPhong((c) => {
            let allVal = {
              TEN_KHOA_PHONG: i18n.t(languageKeys.tat_ca),
              ID: "TAT_CA",
              SO_CHO_KHAM: arr.reduce((total, item) => total += (item.SO_CHO_KHAM || 0), 0),
              TONG_SO_PHIEU: arr.reduce((total, item) => total += (item.TONG_SO_PHIEU || 0), 0)
            }
            handleSelectePhong(allVal, arr)
            return [...arr, allVal]
          })

        } else {
          setDsPhong(c => {
            let newArr = []
            newArr = [...response.result];
            let allVal = c.find(i => i.ID === "TAT_CA");

            if (allVal) {
              newArr = [...newArr, allVal];
            }

            return newArr
          });
          const newPhongSelected = response.result.find(item => item.ID = selectedPhongId);
          form.setFields([
            {
              name: "PHONG",
              value: JSON.stringify(newPhongSelected),
            },])
        }
      }
    }
  }

  function handleSelectePhong(phong, arr_phong = dsPhong) {
    if (typeof phong === 'string') {
      phong = JSON.parse(phong);
    }
    if (phong.ID === "TAT_CA") {
      setSelectedPhong(arr_phong.map(item => item.ID));
      setSelectedPhongId(arr_phong.map(item => item.ID));
      form.setFields([
        {
          name: "PHONG",
          value: JSON.stringify(phong),
        },
      ]);
      return
    }
    if (!selectedPhongId || (selectedPhongId && phong.ID !== selectedPhongId)) {
      selectedPhieu && setSelectedPhieu();
      localStorage.setItem(keys.ris_phong_da_chon, JSON.stringify(phong));
      setSelectedPhongId(phong.ID);
      setSelectedPhong(phong.ID);
      form.setFields([
        {
          name: "PHONG",
          value: JSON.stringify(phong),
        },
      ]);

    }
  }

  // thay đổi tab trạng thái
  function handleChangeTab(itemKey) {
    window.history.pushState({ path: window.location.origin + window.location.pathname }, "", window.location.origin + window.location.pathname)
    setTabActive(itemKey);
    setSelectedPhieu();
  }

  // thay đổi thời gian filter
  function onChangeTimeFilter(values) {
    // setFromDate(moment(values[0]).format("YYYYMMDD"))
    // setToDate(moment(values[1]).format("YYYYMMDD"))
    layDsBenhNhan({
      ...dataRequest,
      TU_NGAY: moment(values[0]).format("YYYYMMDD"),
      DEN_NGAY: moment(values[1]).format("YYYYMMDD"),
      page: 1,
    });
  }

  // thay đổi thời gian filter
  function handleResetFilter(values) {
    window.history.pushState({ path: window.location.origin + window.location.pathname }, "", window.location.origin + window.location.pathname)
    //setSearchString("")
    layDsBenhNhan({
      ...dataRequest,
      TU_NGAY: moment(values[0]).format("YYYYMMDD"),
      DEN_NGAY: moment(values[1]).format("YYYYMMDD"),
      //search_string : ""
    });
  }

  // function khi enter search
  function handleSubmitSearch(e) {
    let txt = e.target.value;
    if (txt.length < keys.limit_min_length && txt.length > 0) {
      return null;
    } else {
      // lấy danh sách bệnh nhân
      layDsBenhNhan({
        ...dataRequest,
        search_string: txt,
        page: 1,
      });
    }
  }

  // button load more
  const loadMore =
    currentPage < totalPage ? (
      <Row justify="center" style={{ marginBlock: 20 }}>
        <Button
          type="primary"
          onClick={() => {
            layDsBenhNhan({ ...dataRequest, page: currentPage + 1 });
          }}
        >
          {i18n.t(languageKeys.btn_xem_them)}
        </Button>
      </Row>
    ) : null;

  const memoDsPhong = useMemo(() => {
    if (!!dsPhong.length) {
      return dsPhong;
    } else {
      return [...dsPhong, {
        TEN_KHOA_PHONG: i18n.t(languageKeys.tat_ca),
        ID: "TAT_CA",
        SO_CHO_KHAM: dsPhong.reduce((total, item) => total += (item.SO_CHO_KHAM || 0), 0),
        TONG_SO_PHIEU: dsPhong.reduce((total, item) => total += (item.TONG_SO_PHIEU || 0), 0)
      }]
    }
  }, [dsPhong]);


  const RenderPhieuItem = ({ item, selectedPhieu }) => {
    return (
      <div
        className={selectedPhieu && selectedPhieu.PHIEU_ID === item.PHIEU_ID ? styles["patient-active"] : styles["patient"]}
        onClick={() => {
          window.history.pushState({ path: window.location.origin + window.location.pathname }, "", window.location.origin + window.location.pathname)
          setSelectedPhieu(item)
        }}
      >
        <Row align="middle" justify="space-between" className={cn(styles["inner"], styles["head"])} wrap={false}>
          <Col className={styles["content"]}>{item.TEN}</Col>
          <Col className={styles["content"]}>
            {!!item.NGAY_SINH ?
              moment().diff(moment(item.NGAY_SINH, item?.NGAY_SINH?.lenght == 8 ? 'YYYYMMDD' : 'YYYY'), 'years')
              : ""
            }
          </Col>
        </Row>
        <Row justify="space-between" className={cn(styles["inner"])} wrap={false}>
          <Col flex={"50%"}>
            <div className={styles["title"]}>{i18n.t(languageKeys.field_Gioi_tinh)}</div>
            <div className={styles["content"]}>{getSexFromString(item.GIOI_TINH)}</div>
          </Col>
          <Col flex={"40%"}>
            <div className={styles["title"]}>{i18n.t(languageKeys.field_Ngay_kham)}</div>
            <div className={styles["content"]}>{convertDateToValue(item.NGAY_KHAM)}</div>
          </Col>
        </Row>

        <Row justify="space-between" className={cn(styles["inner"])} wrap={false} >
          <Col flex={"50%"}>
            <div className={styles["title"]}>{i18n.t(languageKeys.ma_bn)}</div>
            <div className={styles["content"]}>{item.MA_BENH_NHAN}</div>
          </Col>
          <Col flex={"40%"}>
            <div className={styles["title"]}>{i18n.t(languageKeys.field_Ma_CK)}</div>
            <div className={styles["content"]}>{item.MA_CUOC_KHAM}</div>
          </Col>
        </Row>

        {item.NGUON_TAO === "TIEP_DON" && (
          <div className={styles.flex}>
            <RecepIcon />
            <p>{i18n.t(languageKeys.le_tan)}</p>
          </div>
        )}
        {item.NGUON_TAO === "DAT_HEN" && (
          <div className={styles.flex}>
            <Note2 />
            <p>{i18n.t(languageKeys.dat_hen)}</p>
          </div>
        )}
        {item.NGUON_TAO === "TAI_KHAM" && (
          <div className={styles.flex}>
            <Calendar2 />
            <p>{i18n.t(languageKeys.field_Tai_kham)}</p>
          </div>
        )}

      </div>
    );
  };

  return (
    <Layout.Sider className={styles["container"]} width={280}>
      <div className={cn(styles["wrapper"], styles["sticky"])}>
        <Row gutter={[5, 10]}>
          <Col span={24} className={styles["select-phong-container"]}>
            <Form form={form}>
              <Form.Item name={"PHONG"}>
                <Select
                  className={styles["select-phong-input"]}
                  allowClear={false}
                  style={{ width: "100%" }}
                  dataSource={memoDsPhong}
                  labelKey="TEN_KHOA_PHONG"
                  // customTitle={(item) => {
                  //   return <Row wrap={false} justify={"space-between"}>
                  //   <Col flex={"auto"}><Typography.Text style={{color : "#2c3782"}} ellipsis={true}>{item.TEN_KHOA_PHONG}</Typography.Text></Col>
                  //   <Col flex={"30px"} style={{marginLeft:5, color : "#2c3782"}}>{item.SO_CHO_KHAM || 0}/{item.TONG_SO_PHIEU || 0}</Col>
                  // </Row>
                  // }}
                  onSelect={(value) => {
                    handleSelectePhong(value, dsPhong)
                  }}

                  popupClassName={styles["popup-select"]}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24}>
            <Row wrap={false} gutter={5}>
              <Col flex={"auto"}>
                <Input
                  value={searchString}
                  allowClear={true}
                  prefix={<SearchOutlined style={{ color: "#6576FF" }} />}
                  style={{ width: "100%" }}
                  placeholder={i18n.t(languageKeys.common_Tim_kiem) + " (F4)"}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (!value) handleSubmitSearch(e);

                    setSearchString(value);
                  }}
                  onPressEnter={handleSubmitSearch}
                />
              </Col>

              <Col>
                <Button type="primary" icon={<BarcodeOutlined style={{ color: "#fff" }} />}></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className={styles["wrapper"]} style={{ padding: "10px" }}>
        {/* <FilterDate ref={refFilterDate} onReset={handleResetFilter} onFilterTime={onChangeTimeFilter} /> */}
        <ComplexDatePicker
          ref={refFilterDate}
          onReset={handleResetFilter}
          isShowAddition={true}
          showBtn={true}
          onApply={onChangeTimeFilter}
        />
      </div>

      <div className={styles["wrapper"]}>
        <div className={styles["tabs"]}>
          {Object.values(tabItemsKey).map((item) => (
            <Button
              className={tabActive === item ? styles["button-active"] : styles["button"]}
              size="small"
              type="primary"
              key={item}
              onClick={() => handleChangeTab(item)}
            >
              {/* {tabItemTitle[`${item}`]} */}
              <p style={{display:"flex", gap: 4}}><span>{tabItemTitle[`${item}`]}</span> <span>{demSoLuong[`${item}`]}</span></p>
            </Button>
          ))}
        </div>

        <List
          loading={loading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={dsPhieu}
          renderItem={(item, index) => <RenderPhieuItem item={item} selectedPhieu={selectedPhieu} />}
          rowKey={rid()}
        />
      </div>
    </Layout.Sider>
  );
}

export default Sider;
