import { Layout, Spin } from "antd";
import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { paths } from "../constants";
import keys, { featureKeys, keyLoaiNhanVien } from "../constants/keys";
import { doCheckUser } from "../ducks/slices/authSlice";
import { HLog, isAccessAllowed, isJsonString, rid } from "../helpers";
import { useEventAppointment, useIndexedDb, useQuery } from "../hooks";
import i18n, { languageKeys } from "../i18n";
import { Header, MainSider } from "../layouts";
import {
  BangDieuKhien,
  CuocHenSapToi,
  DichVuCanThanhToan,
  MauMoTaCdha,
  NotFound,
  PhauThuatThuThuat,
  QuanLyBenhNhan,
  QuanLyKhamBenh,
  QuanLyDvChiDinh,
  QuanLySoHoaDon,
  QuanLyTiepDon,
  SoHoaDon,
  Support,
  TraKetQuaCdha,
  QuanLyTamUng,
  QuanlyCongNo,
  QuanLySuDungGoiDichVu,
  QLDangKyGoiDichVu,
  DanhSachGoiDichVu
} from "../pages";
import EventSourceClient from "../routes/eventSource/EventSourceClient";
import { FeatureRoute } from "./route";
import RightMenu from "layouts/RightMenu";
import { routeApp } from "../constants/keys";

export const CisRoute = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { user, tokens } = useSelector((state) => state.auth);
  const { loadingInitData } = useIndexedDb();
  const { eventClient, reloadAppointment } = useEventAppointment();

  const currentRoute = window.location.href;
  useEffect(() => {
    const queryLang = query.get(keys.lang);
    handleToken(tokens, queryLang);
  }, [tokens?.token]);

  const handleToken = async (tokens, lang) => {
    try {
      if (!user && !!tokens) {
        dispatch(doCheckUser(tokens));
      }
    } catch (error) {
      HLog(error);
    }
  }

  const redirectPath = useMemo(() => {
    let path = paths.quan_ly_tiep_don;

    let dsQuyen = user && user.QUYEN_MODULE || JSON.stringify([]);

    if (isJsonString(dsQuyen)) {
      dsQuyen = JSON.parse(dsQuyen);
    }

    HLog("dsQuyen", dsQuyen, typeof dsQuyen);

    if (!!dsQuyen && Array.isArray(dsQuyen) && dsQuyen.length > 0) {
      const loai_nhan_su = user.NHOM_NHAN_SU;

      if (loai_nhan_su === keyLoaiNhanVien.bac_si && isAccessAllowed(user, featureKeys.quan_ly_kham_benh)) {
          path = paths.quan_ly_kham_benh;
      } else if (loai_nhan_su === keyLoaiNhanVien.tiep_tan && isAccessAllowed(user, featureKeys.quan_ly_tiep_don)) {
        path = paths.quan_ly_tiep_don;
      } else if (loai_nhan_su === keyLoaiNhanVien.thu_ngan && isAccessAllowed(user, featureKeys.quan_ly_dich_vu_can_thanh_toan)) {
        path = paths.quan_ly_dich_vu_can_thanh_toan;
      } else if (loai_nhan_su === keyLoaiNhanVien.thu_kho && isAccessAllowed(user, featureKeys.ban_thuoc)) {
        path = paths.ban_thuoc;
      } else {
        for (const route of featureRoutes) {
          if ([...dsQuyen].some((quyen) => quyen.includes(route.key))) {
            path = route.path;
            break;
          }
        }
      }
    }

    HLog("redirect to", path);

    return path;
  }, [user]);

  if (!user) {
    return <LoadingComponent />;
  }
  return (
    <Spin
      spinning={loadingInitData}
      tip={
        <div style={{ textAlign: "center" }}>
          <div>{i18n.t(languageKeys.text_khoi_tao_du_lieu)}</div>
        </div>
      }
    >
      {!loadingInitData ? <App redirectPath={redirectPath} currentRoute={currentRoute} user={user} /> : <div style={{width:"100vw",height:"100vh"}}></div>}

      <EventSourceClient ref={eventClient} callEventHandlers={{ reloadAppointment }} />
    </Spin>
  );
};

export const featureRISRoutes = [paths.mau_mo_ta_cdha, paths.tra_ket_qua_cdha, paths.bao_cao_cdha];

const App = memo(({ redirectPath, currentRoute, user }) => {

  if(!!user?.config && !!user.config.CUSTOM_DOMAIN) {
    Object.keys(user.config.CUSTOM_DOMAIN).forEach(key => {
      if(key in window) {
        window[key] = user.config.CUSTOM_DOMAIN[key];
      }
    })
  }
  return (
    <Layout>
      <MainSider />

      <Layout>
        <Header />

        <Layout>
          <Layout.Content>
            <Switch>
              {featureRoutes.map((route) => (
                <FeatureRoute key={rid()} exact path={route.path} component={route.component} featureKey={route.key} />
              ))}

              <Route exact path={paths.ho_tro_khach_hang} component={Support} />

              <Redirect exact from={paths.cis} to={redirectPath} />

              <Route component={NotFound} />
            </Switch>
          </Layout.Content>
          <RightMenu currentFeature={routeApp.CIS}/>
        </Layout>
      </Layout>
    </Layout>
  );
});

const LoadingComponent = () => (
  <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <Spin spinning />
    <h3 className="blue-txt">{i18n.t(languageKeys.updatingAccInfo)}...</h3>
  </div>
);

const featureRoutes = [
  {
    key: `${featureKeys.quan_ly_tiep_don}.${featureKeys.tiep_don}`,
    path: paths.quan_ly_tiep_don,
    component: QuanLyTiepDon,
  },
  {
    key: `${featureKeys.quan_ly_tiep_don}.${featureKeys.cuoc_hen_sap_toi}`,
    path: paths.quan_ly_cuoc_hen_sap_toi,
    component: CuocHenSapToi,
  },
  {
    key: `${featureKeys.quan_ly_vien_phi}.${featureKeys.quan_ly_dich_vu_can_thanh_toan}`,
    path: paths.quan_ly_dich_vu_can_thanh_toan,
    component: DichVuCanThanhToan,
  },
  {
    key: `${featureKeys.quan_ly_vien_phi}.${featureKeys.quan_ly_so_hoa_don}`,
    path: paths.quan_ly_so_hoa_don,
    component: SoHoaDon,
  },
  {
    key: `${featureKeys.quan_ly_vien_phi}.${featureKeys.quan_ly_so_hoa_don}`,
    path: paths.thong_tin_so_hoa_don,
    component: QuanLySoHoaDon,
  },
  {
    key: featureKeys.quan_ly_kham_benh,
    path: paths.quan_ly_kham_benh,
    component: QuanLyKhamBenh,
  },
  {
    key: `${featureKeys.quan_ly_kham_benh}.${featureKeys.quan_ly_chi_dinh_dich_vu}`,
    path: paths.quan_ly_dv_chi_dinh,
    component: QuanLyDvChiDinh,
  },
  {
    key: featureKeys.quan_ly_benh_nhan,
    path: paths.quan_ly_benh_nhan,
    component: QuanLyBenhNhan,
  },
  {
    key: `${featureKeys.quan_ly_chan_doan_hinh_anh_ris}.${featureKeys.mau_mo_ta_cdha}`,
    path: paths.mau_mo_ta_cdha,
    component: MauMoTaCdha,
  },
  {
    key: `${featureKeys.quan_ly_chan_doan_hinh_anh_ris}.${featureKeys.tra_ket_qua_cdha}`,
    path: paths.tra_ket_qua_cdha,
    component: TraKetQuaCdha,
  },
  {
    key: featureKeys.phau_thuat_thu_thuat,
    path: paths.phauthuat_thu_thuat,
    component: PhauThuatThuThuat,
  },

    // Gói Dv
  {
    key: `${featureKeys.quan_ly_vien_phi}.${featureKeys.quan_ly_tam_ung}`,
    path: paths.quan_ly_tam_ung,
    component: QuanLyTamUng,
  },
  {
    key: `${featureKeys.quan_ly_vien_phi}.${featureKeys.quan_ly_cong_no}`,
    path: paths.quan_ly_cong_no,
    component: QuanlyCongNo,
  },
  {
    key:  `${featureKeys.quan_ly_goi_dv}.${featureKeys.danh_sach_goi_dv}`,
    path: paths.danh_sach_goi_dv,
    component: DanhSachGoiDichVu,
  },
  {
    key: `${featureKeys.quan_ly_goi_dv}.${featureKeys.quan_ly_dk_goi_dv}`,
    path: paths.quan_ly_dk_goi_dv,
    component: QLDangKyGoiDichVu,
  },
  {
    key: `${featureKeys.quan_ly_goi_dv}.${featureKeys.quan_ly_su_dung_goi_dv}`,
    path: paths.quan_ly_su_dung_goi_dv,
    component: QuanLySuDungGoiDichVu,
  },
  {
    key: featureKeys.bang_dieu_khien,
    path: paths.bang_dieu_khien,
    component: BangDieuKhien,
  },
];
