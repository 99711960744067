import React, { useEffect } from "react"
import { Card, Col, Layout, Row, Spin, Tooltip } from "antd";
import _ from "lodash";
import styles from "./softwareRoute.module.less"
import {
  PhanHeCIS, QuanLy, PhanHeVatTu, PhanHeReport,
  PhanHeMed, PhanHeLIS, PhanHeRIS, PhanHePRM,
  PhanHeKhamDoan
} from "../../assets/svg/index.js";
import { useHistory } from "react-router-dom";
import { paths, urlKeys } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks";
import { doCheckUser } from "ducks/slices/authSlice";
import { routeApp } from "../../constants/keys";
import SubHeader from '../../layouts/SubHeader/SubHeader'
import i18n, { languageKeys } from "../../i18n"
import { HLog, renderAddress } from "helpers";
import qs from "query-string";

export const SoftwareRoute = () => {

  const history = useHistory();
  const { user, tokens } = useSelector((state) => state.auth);

  if (!!user?.config && !!user.config.CUSTOM_DOMAIN) {

    Object.keys(user.config.CUSTOM_DOMAIN).forEach(key => {
      if (key in window) {
        window[key] = user.config.CUSTOM_DOMAIN[key];
      }
    })
  }
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { GOP, TACH } = user?.config?.HIEN_THI_LAYOUT_MODULE || { GOP: [], TACH: [] };

  const moduleFULL = [routeApp.DANH_MUC, routeApp.CIS, routeApp.LIS, routeApp.BAO_CAO, routeApp.VAT_TU, routeApp.RIS, routeApp.PRM, routeApp.KHAM_DOAN]
  let module;

  // mặc định : rỗng hết => để như cũ

  // nếu gộp có CIS : vậy thì module có : danh mục, cis và những cái trong tách. Khi nhấn vào CIS, sẽ hiển thị giao diện cũ với các module theo cái gộp
  // nếu gộp không có CIS : thì hiển thị trong danh mục và trong tách

  /**
   *
   * @param {Array} mergeArr config bệnh viện các module gộp
   * @param {Array} splitArr config bệnh viện các module tách
   * @returns {Array} các module được hiển thị
   */
  const handleConfigModule = (mergeArr, splitArr) => {
    try {
      let result = [];
      let condition = !!tokens && _.isObject(user?.config) && _.isArray(mergeArr) && _.isArray(splitArr);

      if (condition) {
        const { MODULES: tokenConfigModule } = user?.config;

        if (!!tokenConfigModule) {
          if (mergeArr.length === 1 && splitArr.length === 0) {
            result = _.isArray(tokenConfigModule) ? tokenConfigModule : [];
          } else if (mergeArr.length > 1 && splitArr.length === 0) {
            result = _.uniq(splitArr).filter((mod) => tokenConfigModule.includes(mod))
            result = result.length < 1 ? [routeApp.DANH_MUC] : result.push(routeApp.DANH_MUC)
          } else {
            result = _.uniq([routeApp.CIS, ...splitArr]).filter((mod) => tokenConfigModule.includes(mod));
          }
        }
      }
      return result;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  module = handleConfigModule(GOP, [...TACH]);

  let handleGoToCis = () => {};
  let handleGoToLis = () => {};
  let handleGoToDanhMuc = () => {};
  let handleGoToRis = () => {};
  let handleGoToDuoc = () => {};
  let handleGoToPrm = () => {};
  let handleGoToReports = () => {};
  let handleGoToVatTu = () => {};
  let handleGoToKhamDoan = () => {};

  const cisCommonParams = qs.stringify({
    access_token: tokens?.token,
    refresh_token: tokens?.refreshToken,
    // lang: i18n.language
  })

  handleGoToCis = () => history.push(paths.cis);
  handleGoToLis = () => (window.location.href = `${window?.[urlKeys.LIS_WEB_UI_URL]}?${cisCommonParams}`);
  handleGoToDanhMuc = () => (window.location.href = `${window?.[urlKeys.DM_WEB_UI_URL]}?${cisCommonParams}`);
  handleGoToRis = () => history.push(paths.ris);
  handleGoToDuoc = () => history.push(paths.duoc);
  handleGoToPrm = () => (window.location.href = `${window?.[urlKeys.PRM_WEB_UI_URL]}?jwt=${tokens?.token}`);
  handleGoToReports = () => window.location.href = `${window?.[urlKeys.RP_WEB_UI_URL]}?${cisCommonParams}`;
  handleGoToVatTu = () => window.open(`${window?.[urlKeys.SUPPLIES_WEB_UI_URL]}?${cisCommonParams}`, '_blank');
  handleGoToKhamDoan = () => window.open(`${window?.[urlKeys.PHR_WEB_UI_URL]}?${cisCommonParams}`, '_blank');
  // handleGoToPACS = () => window.open("https://live.draid.ai/", '_blank');
  // }

  useEffect(() => {
    // const queryLang = query.get(keys.lang);
    handleToken(tokens);
  }, [tokens?.token]);

  const handleToken = async (tokens) => {
    try {

      if (!user && !!tokens) {
        dispatch(doCheckUser(tokens));
      }
    } catch (error) {
      HLog(error);
    }
  }

  const switchModuleProps = (moduleCis) => {
    let title = <></>;
    let description = <></>;
    let logo = <></>;
    let action = () => {};
    switch (moduleCis) {
      case routeApp.DANH_MUC:
        title = <h2>{i18n.t(languageKeys.data_Quan_tri)}</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_danh_muc)}</span>;
        logo = <QuanLy />;
        action = handleGoToDanhMuc;
        break;
      case routeApp.CIS:
        title = <h2>CIS</h2>
        description = <span>{i18n.t(languageKeys.sub_title_cis)}</span>;
        logo = <PhanHeCIS />;
        action = handleGoToCis;
        break;
      // case routeApp.DUOC:
      //   title = <h2>{i18n.t(languageKeys.menu_Quan_ly_duoc_ngoai_tru)}</h2>;
      //   description = <span>{i18n.t(languageKeys.sub_title_duoc_ngoai_tru)}</span>;
      //   logo = <PhanHeMed />;
      //   action = handleGoToDuoc;
      //   break;
      case routeApp.LIS:
        title = <h2>LIS</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_lis)}</span>;
        logo = <PhanHeLIS />;
        action = handleGoToLis;
        break;
      case routeApp.BAO_CAO:
        title = <h2>{i18n.t(languageKeys.bao_cao_thong_ke)}</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_bao_cao)}</span>;
        logo = <PhanHeReport />;
        action = handleGoToReports;
        break;
      case routeApp.VAT_TU:
        title = <h2>{i18n.t(languageKeys.duoc_vat_tu)}</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_vt)}</span>;
        logo = <PhanHeVatTu />;
        action = handleGoToVatTu;
        break;
      case routeApp.RIS:
        title = <h2>RIS/PACS</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_ris)}</span>;
        logo = <PhanHeRIS />;
        action = handleGoToRis;
        break;
      case routeApp.PRM:
        title = <h2>PRM</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_prm)}</span>;
        logo = <PhanHePRM />;
        action = handleGoToPrm;
        break;
      case routeApp.KHAM_DOAN:
        title = <h2>{i18n.t(languageKeys.kham_doan)}</h2>;
        description = <span>{i18n.t(languageKeys.sub_title_kd)}</span>;
        logo = <PhanHeKhamDoan />;
        action = handleGoToKhamDoan;
        break;
      default:
        break;
    }
    return { title, description, logo, action };
  };

  if (!user) {
    return (
      <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Spin spinning />
      </div>
    );
  }

  return (
    <Layout className={styles['container_v3']}>
      <SubHeader module={"HOME"} />

      <Layout className={styles['content']}>
        <Layout.Header className={styles['header']}>
          <h1>{user.benh_vien.TEN_CSKCB}</h1>
          {/* <p>{i18n.t(languageKeys.home_intro)}</p> */}
        </Layout.Header>
        <Layout.Content className={styles['btn-wrapper']}>
          <Row gutter={[24, 24]} justify={'center'} style={{ width: 1148, marginLeft: 'auto', marginRight: 'auto' }}>

            {Array.isArray(module) &&
              moduleFULL.map(mod =>
                mod === routeApp.VAT_TU
                  ? ((module.includes(routeApp.DUOC) && user.MODULE_QL?.includes(routeApp.DUOC)) ||
                    (module.includes(routeApp.VAT_TU) && user.MODULE_QL?.includes(routeApp.VAT_TU))) ? routeApp.VAT_TU : null
                  : (module.includes(mod) && user.MODULE_QL?.includes(mod)) ? mod : null
              )
                .filter(mod => mod !== null)
                .map((item, index) => {
                  return (
                    <Col span={6} key={index}>
                      <Card hoverable className={styles['box']} onClick={switchModuleProps(item).action}>
                        {switchModuleProps(item).logo}
                        {switchModuleProps(item).title}
                        <Tooltip title={switchModuleProps(item).description || ""} placement="rightBottom">
                          <p className={styles['two-row-clamp']}>{switchModuleProps(item).description}</p>
                        </Tooltip>
                      </Card>
                    </Col>
                  );
                })}

          </Row>
        </Layout.Content>
        <Layout.Footer className={styles['footer']}>
          {/* <Row justify="center">

              <Col style={{ padding: "10px" }}>
                <a href={linkedinLink} target="_blank" rel="noreferrer noopener">
                  <LinkedinFilled style={{ fontSize: "24px" }} />
                </a>
              </Col>
              <Col style={{ padding: "10px" }}>
                <a href={instaLink} target="_blank" rel="noreferrer noopener">
                  <InstagramOutlined style={{ fontSize: "24px" }} />
                </a>
              </Col>
              <Col style={{ padding: "10px" }}>
                <a href={facebookLink} target="_blank" rel="noreferrer noopener">
                  <FacebookFilled style={{ fontSize: "24px" }} />
                </a>
              </Col>
              <Col style={{ padding: "10px" }}>
                <a href={youtubeLink} target="_blank" rel="noreferrer noopener">
                  <YoutubeFilled style={{ fontSize: "24px" }} />
                </a>
              </Col>
            </Row> */}

          <Row style={{ margin: "10px 0 10px" }}>
            <Col span={24} style={{ textAlign: "center" }}>
              {renderAddress(user)}
            </Col>
          </Row>

          {/* <Row justify="center" align="middle" gutter={20} style={{ width: "100%", marginLeft: 0 }} >
              <Col>
                Hotline: 1900 068 856
              </Col>
              <Col>
                <b style={{fontSize: 14}}>Website: </b>
                <a href={navigateLinkWebsite.link} target="_blank" rel="noreferrer noopener" style={{fontSize: 14}}>
                  {navigateLinkWebsite.title}
                </a>
              </Col>
              <Col>
                Email: {navigateLinkWebsite.EMAIL}
              </Col>
            </Row> */}
        </Layout.Footer>

      </Layout>
    </Layout>
  )
}
